<template>
    <b-container fluid>
        <b-row style="margin-top: 50px;">
            <nodes v-if="this.$route.params.page2 == 'nodes'"></nodes>
            <services v-else-if="this.$route.params.page2 == 'services'"></services>
        </b-row>
    </b-container>
</template>
<script>

</script>

<style lang="scss">
    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .spinnerAnim {
        -webkit-animation: rotating 1s linear infinite;
        -moz-animation: rotating 1s linear infinite;
        -ms-animation: rotating 1s linear infinite;
        -o-animation: rotating 1s linear infinite;
        animation: rotating 1s linear infinite;
    }
</style>

<script>
import nodes from '@/pages/dashboard/staff/nodes.vue';
import services from '@/pages/dashboard/staff/services.vue';

export default {
    name: "Service",
    components: {
        nodes,
        services,
    },
    data: () => {
        return {}
    },
    mounted() {},
    methods: {}
}  
</script>