<template>
    <b-container fluid v-if="!this.$route.params.page2">
        <b-alert show style="margin-top: 25px;">{{ Lang.dashboard.domains.buy }}</b-alert>
        <b-button variant="primary" v-b-modal.newDomain style="margin-top: 25px;"><i class="fas fa-globe"></i> {{ Lang.dashboard.domains.new }}</b-button>
        <b-modal id="domaincreate" title="Domain Verification" @ok="recheckdomain(newDomain._id)" okTitle="Verify" centered>
            <b>
                {{ Lang.dashboard.domains.step1 }}
            </b>
            <br>
            <textarea style="width: 100%;" v-bind:value="'stacket=' + newDomain._id + ';' + newDomain.domainKey"></textarea>
            <br>
            <br>
            <b>
                {{ Lang.dashboard.domains.step2 }}
            </b>
        </b-modal>
        <b-modal id="newDomain" v-bind:title="Lang.dashboard.domains.create" ok-only centered @ok="addDomain" v-bind:okTitle="Lang.dashboard.domains.new">
            <b-form-group
                v-bind:label="Lang.dashboard.domains.enterDomain"
            >
                <b-form-input
                id="diskName"
                v-model="newDomainCreate.domain"
                required
                v-bind:placeholder="Lang.dashboard.domains.domain"
                ></b-form-input>
            </b-form-group>
            <b-form-group v-bind:label="Lang.dashboard.components.service.ownership">
                <b-form-radio v-model="newDomainCreate.ownership" name="ownership-radios" value="personal">{{ Lang.dashboard.order.personal }}</b-form-radio>
                <b-form-radio v-model="newDomainCreate.ownership" name="ownership-radios" value="team">{{ Lang.dashboard.order.team }}</b-form-radio>
            </b-form-group>
            <b-form-group
                v-bind:label="Lang.dashboard.order.team"
                v-if="newDomainCreate.ownership == 'team'"
            >
                <b-form-select style="" v-model="newDomainCreate.team" id="networkTeam">
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>{{ Lang.dashboard.order.selectTeam }}</b-form-select-option>
                    </template>
                    <b-form-select-option v-for="team in teams" v-bind:key="team._id" :value="team._id">
                        {{ team.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>
            
        </b-modal>
        <b-row>
            <b-col cols="3" v-for="(domain, index) of domains" v-bind:key="index" @click="goToDomain(domain._id)" style="min-width: 375px; margin-bottom: 25px; cursor: pointer;">
                <b-card-group deck class="mt-3">
                    <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
                        <template v-slot:header>
                            <h6 class="mb-0" style="color: #777676;">
                                <span style="color: #777676;">{{ domain.domain }}</span>
                            </h6>
                        </template>
                        <b-card-footer style="border-color: transparent; background-color: transparent; color:#727272; margin-bottom: -5px;">
                            <b-row>
                                <b-col style="margin-left: -15px;">
                                    <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                        {{ Lang.dashboard.domains.domain }}
                                    </h6>
                                </b-col>
                                <b-col>
                                    <h6 style="text-align: right; font-size: 13px;">
                                        {{ domain.domain }}
                                    </h6>
                                </b-col>
                            </b-row>
                        </b-card-footer>
                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                            <b-row>
                                <b-col style="margin-left: -15px;">
                                    <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                        {{ Lang.dashboard.domains.status.title }}
                                    </h6>
                                </b-col>
                                <b-col cols="8">
                                    <h6 style="text-align: right; font-size: 13px; text-transform: capitalize;">
                                        {{ (domain.registered == true) ? ((domain.processing == true) ? Lang.dashboard.domains.status.process : Lang.dashboard.domains.status.registed)  : domain.verified ? Lang.dashboard.domains.status.verified : Lang.dashboard.domains.status.unverified }}
                                        <span v-if="domain.verified == false">
                                            <i class="fas fa-recycle recheck" style="font-size: 17px; margin-left: 10px; cursor: pointer;" @click="verify(domain._id)" v-b-tooltip.hover v-bind:title="Lang.dashboard.domains.recheck"></i>
                                        </span>
                                    </h6>
                                </b-col>
                            </b-row>
                        </b-card-footer>
                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                            <b-row>
                                <b-col style="margin-left: -15px;">
                                    <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                        {{ Lang.dashboard.domains.type.title }}
                                    </h6>
                                </b-col>
                                <b-col>
                                    <h6 style="text-align: right; font-size: 13px; text-transform: capitalize;">
                                        {{ domain.registered ? Lang.dashboard.domains.type.yes : Lang.dashboard.domains.type.no }}
                                    </h6>
                                </b-col>
                            </b-row>
                        </b-card-footer>
                    </b-card>
                </b-card-group>
            </b-col>
        </b-row>
    </b-container>
    <domain v-else :teams="teams" :services="services"></domain>
</template>

<style>
    .recheck {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .recheck:hover {
        color: #4886FD!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>

<script>
import domain from '@/pages/dashboard/domain.vue';

export default {
    name: "domains",
    props: [
        "teams",
        "services"
    ],
    components: {
        domain
    },
    data: () => {
        return {
            token: "",
            domains: [],
            newDomain: {},
            newDomainCreate: {
                domain: "",
                team: null,
                ownership: 'personal'

            }
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    //
                    this.getDomains();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://dashboard.stacket.dk/&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        getDomains(){
            this.$http.get(this.StacketConfig.api.domains + `/`, {headers: {"authorization": this.token}}).then(response => {
                this.domains = response.data;
            }).catch(err => {
                console.log(err);
            })
        },
        addDomain(){
            this.$http.post(this.StacketConfig.api.domains, this.newDomainCreate, {headers: {"authorization": this.token}}).then(response => {
                if(response.data.error){
                     return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "ERROR",
                        text: response.data.error,
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success!",
                    text: response.data.message,
                })
                this.newDomain = response.data;
                this.getDomains();
                this.$bvModal.show('domaincreate');
            }).catch(err => {
                console.log(err);
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: e.response.data.error,
                })
            })
        },
        goToDomain(id){
            if(this.domains.find(e => e._id == id).verified == true){
                this.$router.push("/domains/" + id)
            } else {
                console.log(id)
                this.newDomain = this.domains.find(e => e._id.toString() == id.toString());
                this.$bvModal.show('domaincreate');
            }
        },
        verify(id){
            console.log(id)
            this.newDomain = this.domains.find(e => e._id.toString() == id.toString());
            this.$bvModal.show('domaincreate');
        },
        recheckdomain(id){
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Verifying Domain..",
                text: "Please wait"
            });
            this.$http.get(this.StacketConfig.api.domains + `/${id}/verify`, {headers: {"authorization": this.token}}).then(response => {
                if(response.data.error){
                     return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "ERROR",
                        text: response.data.error,
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success!",
                    text: response.data.message,
                })
                this.getDomains();
            }).catch(err => {
                console.log(err);
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: e.response.data.error,
                })
            })
        }
    }
}  
</script>