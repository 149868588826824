import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Dashboard from '@/views/dashboard.vue';

export default new Router({
    routes: [
        {
            name: 'Dashboard',
            component: Dashboard,
            path: '/:page?/:page2?/:page3?/:page4?'
        }
    ],
    mode: 'history'
});
