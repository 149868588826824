<template>
    <b-container fluid style="color: rgb(73, 80, 87); font-family: 'Roboto';">
        <b-row class="mt-5">
            <b-col cols="1"></b-col>
            <b-col>
                <h1 style="color: #4886FD;">{{ Lang.dashboard.service.versionManager.title }}</h1>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
        <b-row>
            <b-col><hr></b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="1"></b-col>
            <b-col>
                <div>
                    <b-tabs content-class="mt-3" class="mt-3">
                        <b-tab v-for="(versionList, platform) of versions" v-bind:title="platform.replace(/(^.)/, m => m.toUpperCase())" v-bind:key="platform">
                            <div>
                                <div v-for="version in versionList.slice(0, 30)" v-bind:key="version" :class="(selected.platform == platform && selected.version == version) ? 'creationButton selectedCreationButton' : 'creationButton'" @click="selected.platform = platform; selected.version = version">
                                    <img v-bind:src="platforms[platform]">
                                    <h5>
                                        <span v-if="platform == 'bungeecord' || platform == 'velocity' || platform == 'waterfall'">#</span>
                                        {{ version }}
                                    </h5>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="1"></b-col>
            <b-col>
                <b-button @click="changeVersion()" variant="primary" style="position: fixed; bottom: 30px;width: 65%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                    {{ Lang.dashboard.service.versionManager.confirm }}
                </b-button>
            </b-col>
        </b-row>
        
    </b-container>
</template>
<script>

</script>
<style>

</style>
<script>

export default {
    name: "Version Manager",
    components: {
        
    },
    data: () => {
        return {
            token: "",
            service: {},
            selected: {
                type: "spigot",
                version: "1.9.8"
            },
            versions: {},
            platforms: {
                "spigot": require("@/assets/images/services/versions/spigot.png"),
                "paper": require("@/assets/images/services/versions/paper.png"),
                "vanilla": require("@/assets/images/services/versions/vanilla.png"),
                "bungeecord": require("@/assets/images/services/versions/bungeecord.png"),
                "velocity": require("@/assets/images/services/versions/velocity.png"),
                "waterfall": require("@/assets/images/services/versions/paper.png"),
                "node": require("@/assets/images/services/versions/node.png"),
                "nginx": require("@/assets/images/services/versions/nginx.png"),
                "nginx-php": require("@/assets/images/services/versions/nginx.png"),
                "csgo": require("@/assets/images/services/versions/csgo.png"),
                "mysql": require("@/assets/images/services/versions/mysql.png"),
                "ubuntu": require("@/assets/images/services/versions/ubuntu.png"),
            }
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        this.service = this.$parent.service;

        var split = this.service.service.version.split("-");
        this.selected = {
            platform: split[0],
            version: split[1]
        }

        this.getVersions();
    },
    methods: {
        changeVersion(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, {version: {platform: this.selected.platform, version: this.selected.version}}, {headers: {"authorization": this.token}}).then(async (response) => {
                if(!response.data.error){
                    this.$parent.service.service.version = this.selected.platform + "-" + this.selected.version;
                    return this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Success",
                        text: "Version is now changed!"
                    })
                } else {
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch versions.\nTry again later!"
                    })
                }
            });
        },
        getVersions(){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/versions", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data){
                    this.versions = response.data;
                } else {
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch versions.\nTry again later!"
                    })
                }
            });
        }
    }
}  
</script>