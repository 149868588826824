<template>
    <b-container fluid>
        <b-row style="margin-top: 50px; margin-bottom: 25px;">
            <b-col>
                <h4>
                    Filter
                </h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col>
                        <p style="font-weight: bold;">
                            Status
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="1" v-for="(value, index) of status" v-bind:key="index">
                        <b-form-checkbox v-model="status[index]" style="text-transform: capitalize;">
                        {{ index }}
                      </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 25px;">
                    <b-col>
                        <p style="font-weight: bold;">
                            Types
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="1" v-for="(value, index) of types" v-bind:key="index">
                        <b-form-checkbox v-model="types[index]" style="text-transform: capitalize;">
                        {{ index }}
                      </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 25px;">
                    <b-col>
                        <p style="font-weight: bold;">
                            Nodes
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="1" v-for="(value, index) of nodes" v-bind:key="index">
                        <b-form-checkbox v-model="nodes[index]" style="text-transform: uppercase;">
                        {{ index }}
                      </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 25px;">
                    <b-col cols="3">
                        <p style="font-weight: bold;">
                            Owner / Team
                        </p>
                    </b-col>
                    <b-col cols="3">
                        <p style="font-weight: bold;">
                            Service Id
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <b-input-group>
                            <b-form-input v-model="owner" placeholder="Owner / Team Id"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="3">
                        <b-input-group>
                            <b-form-input v-model="serviceId" placeholder="Service Id"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row style="margin-top: 50px;">
            <span v-for="(service, index) of services" v-bind:key="index">
                <b-col cols="3" v-if="show(service) == true" style="min-width: 375px; margin-bottom: 25px;">
                    <serviceCard :service="service"></serviceCard>
                </b-col>
            </span>
        </b-row>
    </b-container>
</template>
<script>

</script>
<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>
<script>
import serviceCard from '@/components/serviceCard.vue';

export default {
    name: "Staff",
    components: {
        serviceCard
    },
    data: () => {
        return {
            token: "",
            services: [],
            owner: "",
            serviceId: "",
            nodes: {
                "fsn6": true,
                "fsn7": true,
                "fsn8": true,
                "fsn9": true,
                "fsn10": true,
                "fsn11": true,
                "fsn12": true,
                "fsn13": true,
                "fsn14": true,
                "aad1": true,
                "aad2": true,
                "aad3": true,
                "aad4": true,
                "aad5": true,
                "aad6": true,
                "aad7": true,
                "aad8": true,
                "mo1": true,
            },
            types: {
                "minecraft": true,
                "terraria": true,
                "csgo": true,
                "rust": true,
                "nodejs": true,
                "nginx": true,
                "nginx-php": true,
                "mysql": true,
                "deno": true,
                "python": true,
                "mongodb": true,
                "fivem": true,
                "vps": true,
            },
            status: {
                "online": true,
                "offline": true,
                "hibernating": true,
            }
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.getStaffServices();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://stacket." + this.tld + "/dashboard&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        show(service){
            if(this.nodes[service.node] && this.types[service.type] && this.status[service.status]){
                if(this.owner != ""){
                    console.log(this.owner)
                    if(service.owner) {
                        if(JSON.stringify(service.owner).toLowerCase().includes(this.owner.toLowerCase())) {
                            if(this.serviceId != ""){
                                if(service._id.includes(this.serviceId)) return true
                                return false;
                            }
                            return true;
                        };
                        return false;
                    } else if(service.team){
                        if(service.team.id.includes(this.owner)){
                            if(this.serviceId != ""){
                                if(service._id.includes(this.serviceId)) return true
                                return false;
                            }
                            return true;
                        }
                        return false;
                    }
                }
                if(this.serviceId != ""){
                    if(service._id.includes(this.serviceId)) return true
                    return false;
                }
                return true;
            };
            return false;
        },
        allocated(node){
            var srvs = this.services.filter(srv => srv.node == node && srv.status != 'hibernating');

            var ram = function(pkg){
                var clean = parseInt(pkg.replace("pkg", ""));
                if(clean == 0) clean = 0.5;
                return clean;
            }

            var reducer = function(e, v){
                return e + ram(v.package);
            }
            return srvs.reduce(reducer, 0)
        },
        convertName(name) {
            var fullname = name+"";
            var split = fullname.split(" ");
            if(split.length == 1){
                return split[0].charAt(0) + split[0].charAt(1);
            } else {
                var firstName = split[0];
                var lastName = split[split.length-1];

                return firstName.charAt(0) + lastName.charAt(0);
            }

        },
        getStaffServices() {
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            this.$http.get(this.StacketConfig.api.services + "/staff", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                loader.hide();
                this.services = response.data;
                for(var service in this.services){
                    if(!this.services[service].team){
                        if(this.services[service].subusers && this.services[service].subusers.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.services[service]._id}/subusers`, {headers: {"authorization": this.token}});
                            this.services[service].subussers = resp.data;    
                        }
                    } else {
                        if(this.services[service].team.groups && this.services[service].team.groups.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.services[service]._id}/groups`, {headers: {"authorization": this.token}});
                            this.services[service].team.groups = resp.data;    
                        }
                    }
                }
            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch services!"
                })
            });
        }
    }
}  
</script>