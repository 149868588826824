<template>
    <b-container fluid v-if="!this.$route.params.page2">
        <b-row class="mt-4">
            <b-col>
                <b-row class="mt-3">
                    <b-col>

                        <b-modal id="selectVersion" ok-title="Cancel" title="Select Private Network Version">
                            <h6 style="font-weight: bold;">
                                Version 1.0
                            </h6>
                            <p>
                                Private networking version 1.0 allows some services to connect privately. This version comes with some limitations such as not supporting all service types and being less reliable than newer generations. <u>Not available for AAD nodes.</u>
                            </p>
                            <b-button variant="secondary" @click="$bvModal.hide('selectVersion'); $bvModal.show('newNetwork')">Select</b-button>
                            <hr>
                            <h6 style="font-weight: bold;">
                                Version 2.0
                                <b-badge variant="primary">recommended</b-badge>
                            </h6>
                            <p>
                                Private networking version 2.0 allows all services to connect privately. This iteration of private networking is much more reliable, supports dedicated ip addresses and much more. <u>Limited to AAD nodes only.</u>
                            </p>
                            <b-button variant="primary" @click="$bvModal.hide('selectVersion'); $bvModal.show('newNetworkNew')">Select</b-button>
                            </b-modal>

                        <b-button variant="primary" v-b-modal.selectVersion><i class="fas fa-project-diagram"></i> {{ Lang.dashboard.networks.newNetwork }}</b-button>

                        <b-modal id="newNetwork" v-bind:title="Lang.dashboard.networks.newNetwork" ok-only centered @ok="createNetwork" v-bind:modal-ok="Lang.dashboard.networks.modal.createNetwork">
                            <b-form-group
                                v-bind:label="Lang.dashboard.networks.modal.networkName"
                            >
                                <b-form-input
                                id="networkName"
                                v-model="newNetwork.name"
                                required
                                v-bind:placeholder="Lang.dashboard.networks.modal.enterName"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group v-bind:label="Lang.dashboard.networks.networkType">
                                <b-form-radio v-model="newNetwork.type" name="type-radios" value="ipv6">IPv6</b-form-radio>
                                <b-form-radio v-model="newNetwork.type" name="type-radios" value="ipv4">IPv4</b-form-radio>
                            </b-form-group>
                            <b-form-group v-bind:label="Lang.dashboard.order.ownership">
                                <b-form-radio v-model="newNetwork.ownership" name="ownership-radios" value="personal">{{ Lang.dashboard.order.personal }}</b-form-radio>
                                <b-form-radio v-model="newNetwork.ownership" name="ownership-radios" value="team">{{ Lang.dashboard.order.team }}</b-form-radio>
                            </b-form-group>
                            <b-form-group
                                label="Team"
                                v-if="newNetwork.ownership == 'team'"
                            >
                                <b-form-select style="" v-model="newNetwork.team" id="networkTeam">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>{{ Lang.dashboard.order.selectTeam }}</b-form-select-option>
                                    </template>
                                    <b-form-select-option v-for="team in teams" v-bind:key="team._id" :value="team._id">
                                        {{ team.name }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            
                        </b-modal>
                        <b-modal id="newNetworkNew" v-bind:title="Lang.dashboard.networks.newNetwork" ok-only centered @ok="createNetworknew" v-bind:modal-ok="Lang.dashboard.networks.modal.createNetwork">
                            <b-form-group
                                v-bind:label="Lang.dashboard.networks.modal.networkName"
                            >
                                <b-form-input
                                id="networkName"
                                v-model="newNetwork2.name"
                                required
                                v-bind:placeholder="Lang.dashboard.networks.modal.enterName"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group v-bind:label="Lang.dashboard.order.ownership">
                                <b-form-radio v-model="newNetwork2.ownership" name="ownership-radios" value="personal">{{ Lang.dashboard.order.personal }}</b-form-radio>
                                <b-form-radio v-model="newNetwork2.ownership" name="ownership-radios" value="team">{{ Lang.dashboard.order.team }}</b-form-radio>
                            </b-form-group>
                            <b-form-group
                                label="Team"
                                v-if="newNetwork2.ownership == 'team'"
                            >
                                <b-form-select style="" v-model="newNetwork2.team" id="networkTeam">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>{{ Lang.dashboard.order.selectTeam }}</b-form-select-option>
                                    </template>
                                    <b-form-select-option v-for="team in teams" v-bind:key="team._id" :value="team._id">
                                        {{ team.name }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            
                        </b-modal>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 20px;">
                    <b-col cols="3" v-for="(network, index) of networks" v-bind:key="index" style="min-width: 375px; margin-bottom: 25px;">
                        <networkCard :network="network" :key="index"></networkCard>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
    <networkNew v-else-if="$route.params.page2 == 'new'" :folders="folders" :teams="teams" :networks="networks" :services="services"></networkNew>
    <network v-else :teams="teams" :networks="networks" :services="services"></network>
</template>

<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>

<script>
import networkCard from '@/components/networkCard.vue';
import network from '@/pages/dashboard/network.vue';
import networkNew from '@/pages/dashboard/networkNew.vue';

export default {
    name: "Networks",
    props: [
        "teams",
        "networks",
        "services",
        "folders"
    ],
    components: {
        networkCard,
        network,
        networkNew
    },
    data: () => {
        return {
            token: "",
            connectId: "",
            additionalIPs: [],
            newNetwork: {
                name: null,
                type: 'ipv6',
                team: null,
                ownership: 'personal'
            },
            newNetwork2: {
                name: null,
                team: null,
                ownership: 'personal'
            }
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    //
                    this.getIPs();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://dashboard.stacket.dk/&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        getIPs(){
            this.$http.get(this.StacketConfig.api.networks + `/additionalips`, {headers: {"authorization": this.token}}).then(response => {
                this.additionalIPs = response.data;
            }).catch(err => {
                console.log(err);
            })
        },
        connect(network, service) {
            console.log("Connecting " + service + ", please wait...");
            this.$http.get(this.StacketConfig.api.networks + `/${network}/connect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                console.log(response.data);
                this.$parent.getNetworks();
            }).catch(err => {
                console.log(err);
            })
        },
        disconnect(network, service) {
            console.log("Disconnecting " + service + ", please wait...");
            this.$http.get(this.StacketConfig.api.networks + `/${network}/disconnect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                console.log(response.data);
                this.$parent.getNetworks();
            }).catch(err => {
                console.log(err);
            })
        },
        createNetwork() {
            this.$http.post(this.StacketConfig.api.networks + `/`, this.newNetwork, {headers: {"authorization": this.token}}).then(response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "SUCCESS",
                    text: "Network created!"
                });
                this.$parent.getNetworks();
                this.newNetwork = {
                    name: null,
                    type: 'ipv6',
                    team: null,
                    ownership: 'personal'
                }
            }).catch(err => {
                if(err.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: err.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create network!"
                })
            });
        },
        createNetworknew() {
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            this.$http.post(this.StacketConfig.api.networks + `/new/`, this.newNetwork2, {headers: {"authorization": this.token}}).then(response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "SUCCESS",
                    text: "Network created!"
                });
                this.$parent.getNetworks();
                this.newNetwork2 = {
                    name: null,
                    team: null,
                    ownership: 'personal'
                }
                loader.hide();
            }).catch(err => {
                loader.hide();
                if(err.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: err.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create network!"
                })
            });
        }
    }
}  
</script>