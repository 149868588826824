<template>
    <b-container fluid style="color: rgb(73, 80, 87); font-family: Roboto;">
        <b-row class="mt-5 ml-2">
            <b-col>
                <b-tabs content-class="mt-4" v-model="tabIndex">
                    <b-tab v-bind:title="Lang.dashboard.settings.account.title" active>
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="4">
                                    <h5>{{ Lang.dashboard.settings.account.profile.title }}</h5>
                                    <p>{{ Lang.dashboard.settings.account.profile.info }}</p>
                                    <br>
                                    <h5>
                                        {{ Lang.dashboard.settings.account.userID }}
                                    </h5>
                                    <p>
                                        {{ profile._id }}
                                    </p>
                                </b-col>
                                <b-col cols="1">
                                    <img :src="profile.image" width=75px style="border-radius: 50%; border: 1px solid lightgray;">
                                </b-col>
                                <b-col class="mt-2" cols="5">
                                    <h6>{{ Lang.dashboard.settings.account.name }}</h6>
                                    <b-form-input v-bind:placeholder="Lang.dashboard.settings.account.namePlaceholder" v-model="newProfile.fullname">

                                    </b-form-input>
                                    <h6 class="mt-3">{{ Lang.dashboard.settings.account.email }}</h6>
                                    <b-form-input v-bind:placeholder="Lang.dashboard.settings.account.emailPlaceholder" type="email" v-model="newProfile.email">

                                    </b-form-input>
                                    <h6 class="mt-3">{{ Lang.dashboard.settings.account.company }}</h6>
                                    <b-form-input v-bind:placeholder="Lang.dashboard.settings.account.companyPlaceholder" v-model="newProfile.company">

                                    </b-form-input>

                                    <b-button variant="primary" class="mt-3" @click="updateProfile" v-if="profile.fullname != newProfile.fullname || profile.email != newProfile.email || profile.company != newProfile.company">
                                        {{ Lang.dashboard.settings.account.save }}
                                    </b-button>
                                    <b-button variant="primary" class="mt-3" disabled v-else title="No changes have been applied!">
                                        {{ Lang.dashboard.settings.account.save }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="4">
                                    <h5>{{ Lang.dashboard.settings.account.gdpr.title }}</h5>
                                    <p>{{ Lang.dashboard.settings.account.gdpr.info }}</p>
                                </b-col>
                                <b-col cols="1">
                                    
                                </b-col>
                                <b-col class="mt-2" cols="5">
                                    <b-button variant="primary" class="mt-3" @click="downloadGDPR">
                                        {{ Lang.dashboard.settings.account.gdpr.download }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="4">
                                    <h5>{{ Lang.dashboard.settings.account.u2f.title }}</h5>
                                    <p>
                                        {{ Lang.dashboard.settings.account.u2f.info }}
                                    </p>
                                </b-col>
                                <b-col cols="1"></b-col>
                                <b-col class="mt-2" cols="5">
                                    <span v-if="!profile.u2f">
                                        <b-button variant="primary" class="mt-3" @click="gotoSetup" v-bind:title="Lang.dashboard.settings.account.u2f.setupTitle">
                                            {{ Lang.dashboard.settings.account.u2f.setup }}
                                        </b-button>
                                    </span>
                                    <span v-else>
                                        <b-button variant="danger" class="mt-3" @click="gotoSetup" v-bind:title="Lang.dashboard.settings.account.u2f.disableTitle">
                                            {{ Lang.dashboard.settings.account.u2f.disable }}
                                        </b-button>
                                    </span>
                                </b-col>

                            </b-row>
                        </div>
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="4">
                                    <h5>{{ Lang.dashboard.settings.account["2fa"].title }}</h5>
                                    <p>
                                        {{ Lang.dashboard.settings.account["2fa"].info }}
                                    </p>
                                </b-col>
                                <b-col cols="1"></b-col>
                                <b-col class="mt-2" cols="5">
                                    <span v-if="!profile['2fa']">
                                        <b-button variant="primary" class="mt-3" @click="gotoSetup" v-bind:title="Lang.dashboard.settings.account['2fa'].setupTitle">
                                            {{ Lang.dashboard.settings.account["2fa"].setup }}
                                        </b-button>
                                    </span>
                                    <span v-else>
                                        <b-button variant="danger" class="mt-3" @click="gotoSetup" v-bind:title="Lang.dashboard.settings.account['2fa'].disableTitle">
                                            {{ Lang.dashboard.settings.account["2fa"].disable }}
                                        </b-button>
                                    </span>
                                </b-col>

                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab v-bind:title="Lang.dashboard.settings.billing.title">
                        <span v-if="profile.country.toLowerCase() == 'denmark'">
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                                <b-row>
                                    <b-col cols="1"></b-col>
                                    <b-col cols="4" class="mt-3">
                                        <h5>{{ Lang.dashboard.settings.billing.paymentMethod.title }}</h5>
                                        <p>{{ Lang.dashboard.settings.billing.paymentMethod.info }}</p>
                                    </b-col>
                                    <b-col cols="1">
                                        
                                    </b-col>
                                    <b-col class="mt-4" cols="5">
                                        <b-form-select v-model="billing.method" :options="[{value: 'manual', text: Lang.dashboard.settings.billing.paymentMethod.manual},{value: 'automatic', text: Lang.dashboard.settings.billing.paymentMethod.automatic, disabled: true}]"></b-form-select>
                                    </b-col>
                                </b-row>
                            </div>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                                <b-row style="margin-bottom: 50px;">
                                    <b-col cols="1"></b-col>
                                    <b-col cols="4" class="mt-3">
                                        <h5>{{ Lang.dashboard.settings.billing.refill.title }}</h5>
                                        <p>{{ Lang.dashboard.settings.billing.refill.info }}</p>
                                    </b-col>
                                    <b-col cols="3" class="mt-3" style="text-align: center;">
                                        <h5>{{ Lang.dashboard.settings.billing.currentBalance }}</h5>
                                        <p>{{ Lang.dashboard.order.priceFormat.replace("$v", (profile.balance * Lang.price).toFixed(2)) }}</p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="1"></b-col>
                                    <b-col cols="10" class="mt-3">
                                        <div :class="(billing.amount == 30) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 30">
                                            <h3>30 <small>DKK</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 50) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 50">
                                            <h3>50 <small>DKK</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 75) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 75">
                                            <h3>75 <small>DKK</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 100) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 100">
                                            <h3>100 <small>DKK</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 150) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 150">
                                            <h3>150 <small>DKK</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 200) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 200">
                                            <h3>200 <small>DKK</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 300) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 300">
                                            <h3>300 <small>DKK</small></h3>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row style="margin-top: 25px;">
                                    <b-col>
                                        <center>
                                            <input type="checkbox" v-model="accepted"> Klik her for at acceptere vores <a href="https://stacket.dk/tos" target="_blank">Handelsbetingelser</a>
                                        </center>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" class="mt-3" v-if="accepted == true">
                                        <div style="width: 95%; text-align: center;" class="mt-3">
                                            <b-button variant="primary" @click="purchaseYourPay('creditcard')" style="width: 25%; margin-right: 25px;">
                                                <i class="fas fa-credit-card"></i> Visa / MasterCard
                                            </b-button>
                                            <b-button variant="primary" @click="purchaseYourPay('mobilepay')" class="mobilepayButton" style="width: 25%;">
                                                <img src="https://betaassets.stacket.net/mobilepay/Horisontal_White.svg" style="height: 25px;">
                                            </b-button>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" class="mt-3" v-else-if="accepted == false">
                                        <div style="width: 95%; text-align: center;" class="mt-3">
                                            <b-button disabled variant="primary" style="width: 25%; margin-right: 25px;">
                                                <i class="fas fa-credit-card"></i> Visa / MasterCard
                                            </b-button>
                                            <b-button disabled variant="primary" class="mobilepayButton" style="width: 25%;">
                                                <img src="https://betaassets.stacket.net/mobilepay/Horisontal_White.svg" style="height: 25px;">
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </span>
                        <span v-else>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                                <b-row>
                                    <b-col cols="1"></b-col>
                                    <b-col cols="4" class="mt-3">
                                        <h5>{{ Lang.dashboard.settings.billing.paymentMethod.title }}</h5>
                                        <p>{{ Lang.dashboard.settings.billing.paymentMethod.info }}</p>
                                    </b-col>
                                    <b-col cols="1">
                                        
                                    </b-col>
                                    <b-col class="mt-4" cols="5">
                                        <b-form-select v-model="billing.method" :options="[{value: 'manual', text: Lang.dashboard.settings.billing.paymentMethod.manual},{value: 'automatic', text: Lang.dashboard.settings.billing.paymentMethod.automatic, disabled: true}]"></b-form-select>
                                    </b-col>
                                </b-row>
                            </div>
                            <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                                <b-row style="margin-bottom: 50px;">
                                    <b-col cols="1"></b-col>
                                    <b-col cols="4" class="mt-3">
                                        <h5>{{ Lang.dashboard.settings.billing.refill.title }}</h5>
                                        <p>{{ Lang.dashboard.settings.billing.refill.info }}</p>
                                    </b-col>
                                    <b-col cols="3" class="mt-3" style="text-align: center;">
                                        <h5>{{ Lang.dashboard.settings.billing.currentBalance }}</h5>
                                        <p>{{ Lang.dashboard.order.priceFormat.replace("$v", (profile.balance * Lang.price).toFixed(2)) }}</p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="1"></b-col>
                                    <b-col cols="10" class="mt-3">
                                        <div :class="(billing.amount == 5) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 5">
                                            <h3>{{ Lang.dashboard.order.priceFormatShort.replace("$v", (5 * Lang.price).toFixed(0)) }} <small>{{ Lang.dashboard.order.currency }}</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 10) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 10">
                                            <h3>{{ Lang.dashboard.order.priceFormatShort.replace("$v", (10 * Lang.price).toFixed(0)) }} <small>{{ Lang.dashboard.order.currency }}</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 15) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 15">
                                            <h3>{{ Lang.dashboard.order.priceFormatShort.replace("$v", (15 * Lang.price).toFixed(0)) }} <small>{{ Lang.dashboard.order.currency }}</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 20) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 20">
                                            <h3>{{ Lang.dashboard.order.priceFormatShort.replace("$v", (20 * Lang.price).toFixed(0)) }} <small>{{ Lang.dashboard.order.currency }}</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 25) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 25">
                                            <h3>{{ Lang.dashboard.order.priceFormatShort.replace("$v", (25 * Lang.price).toFixed(0)) }} <small>{{ Lang.dashboard.order.currency }}</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 50) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 50">
                                            <h3>{{ Lang.dashboard.order.priceFormatShort.replace("$v", (50 * Lang.price).toFixed(0)) }} <small>{{ Lang.dashboard.order.currency }}</small></h3>
                                        </div>
                                        <div :class="(billing.amount == 75) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 75">
                                            <h3>{{ Lang.dashboard.order.priceFormatShort.replace("$v", (75 * Lang.price).toFixed(0)) }} <small>{{ Lang.dashboard.order.currency }}</small></h3>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" class="mt-3">
                                        <div style="width: 95%; text-align: center;" class="mt-3">
                                            <b-button v-if="stripe != null" variant="primary" @click="purchase()" style="width: 25%;">
                                                <i class="fas fa-credit-card"></i> {{ Lang.dashboard.settings.billing.purchase }}
                                            </b-button>
                                            <b-button v-else variant="primary" disabled style="width: 25%;" title="Loading payment api">
                                                <i class="fas fa-credit-card"></i> {{ Lang.dashboard.settings.billing.purchase }}
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </span>
                    </b-tab>
                    <b-tab v-bind:title="Lang.dashboard.settings.invoices.title">
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row style="margin-bottom: 50px;">
                                <b-col cols="3">
                                    <h5>{{ Lang.dashboard.settings.invoices.preview }} - {{ getMonthName(new Date()) }} {{ new Date().getFullYear() }}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="2">
                                    <h6>{{ Lang.dashboard.settings.invoices.serviceID }}</h6>
                                </b-col>
                                <b-col cols="3">
                                    <h6>{{ Lang.dashboard.settings.invoices.name }}</h6>
                                </b-col>
                                <b-col cols="1">
                                    <h6>{{ Lang.dashboard.settings.invoices.hours }}</h6>
                                </b-col>
                                <b-col cols="1">
                                    <h6>{{ Lang.dashboard.settings.invoices.price }}</h6>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <hr>
                                </b-col>
                            </b-row>
                            <span v-for="(service, index) of lastPayment.services" v-bind:key="index">
                                <b-row>
                                    <b-col cols="2">
                                        {{ service.id || Lang.dashboard.settings.invoices.custom }}
                                    </b-col>
                                    <b-col cols="3" v-if="service.note || services.find(srv => srv._id.toString() == service.id.toString())">
                                        {{ service.note || (services.find(srv => srv._id.toString() == service.id.toString()) ? services.find(srv => srv._id.toString() == service.id.toString()).name : Lang.dashboard.settings.invoices.unknown) }}
                                    </b-col>
                                    <b-col v-else cols="3">
                                        {{ (service.name ? service.name : Lang.dashboard.settings.invoices.unknown) }} <b-badge pill variant="danger">{{ Lang.dashboard.settings.invoices.deleted }}</b-badge>
                                    </b-col>
                                    <b-col cols="1">
                                        {{ service.hours }}
                                    </b-col>
                                    <b-col cols="1">
                                        {{ Lang.dashboard.order.priceFormat.replace("$v", (service.amount * Lang.price).toFixed(4)) }}
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <hr>
                                    </b-col>
                                </b-row>
                            </span>
                            <span v-if="lastPayment.networks && lastPayment.networks.length != 0">
                                <b-row>
                                    <br>
                                </b-row>
                                <b-row>
                                    <b-col cols="2">
                                        <h6>{{ Lang.dashboard.settings.invoices.networkId }}</h6>
                                    </b-col>
                                    <b-col cols="3">
                                        <h6>{{ Lang.dashboard.settings.invoices.IPAddress }}</h6>
                                    </b-col>
                                    <b-col cols="1">
                                        <h6>{{ Lang.dashboard.settings.invoices.months }}</h6>
                                    </b-col>
                                    <b-col cols="1">
                                        <h6>{{ Lang.dashboard.settings.invoices.price }}</h6>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <hr>
                                    </b-col>
                                </b-row>
                                <span v-for="(network, index) of lastPayment.networks" v-bind:key="index">
                                    <b-row>
                                        <b-col cols="2">
                                            {{ network.id }}
                                        </b-col>
                                        <b-col cols="3" v-if="network.deleted && network.deleted == true">
                                            {{ network.ip }} <b-badge pill variant="danger">{{ Lang.dashboard.settings.invoices.deletedIp }}</b-badge>
                                        </b-col>
                                        <b-col cols="3" v-else>
                                            {{ network.ip }}
                                        </b-col>
                                        <b-col cols="1">
                                            {{ network.months }}
                                        </b-col>
                                        <b-col cols="1">
                                            {{ Lang.dashboard.order.priceFormat.replace("$v", (network.amount * Lang.price).toFixed(4)) }}
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <hr>
                                        </b-col>
                                    </b-row>
                                </span>
                            </span>
                            <b-row>
                                <br>
                            </b-row>
                            <b-row>
                                <b-col cols="3">
                                    <h6>{{ Lang.dashboard.settings.invoices.total}}</h6>
                                    <span v-if="usedBalance">
                                        {{ Lang.dashboard.order.priceFormat.replace("$v", (usedBalance * Lang.price).toFixed(4)) }}
                                    </span>
                                    <span v-else>
                                        {{ Lang.dashboard.order.priceFormat.replace("$v", (0 * Lang.price).toFixed(4)) }}
                                    </span>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <!--<b-tab title="Discord Management">
                        <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row>
                                <b-col cols="4">
                                    <h5>Stacket's official Discord bot</h5>
                                    <p>You can utilize our official discord to display player count statistics or use our commands to control your service.</p>
                                    <p>The bot comes along with a highly customizable permission system to insure your services are kept safe.</p>
                                </b-col>
                                <b-col cols="2">
                                </b-col>
                                <b-col cols="4">
                                    <h5>
                                        Getting started
                                    </h5>
                                    <p>
                                        To get started click <a href="#">here</a> to invite the bot to your discord.
                                    </p>
                                    <p>
                                        Once invited, click <a href="#">here</a> to generate a one-time token which can be entered by using <code>!token %code%</code> in any text channels.
                                    </p>
                                </b-col>
                            </b-row>
                        </div>
                        <span v-if="profile.discordguilds">
                            <div v-for="(guild, index) of profile.discordguilds" v-bind:key="index" style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                                <b-row>

                                    <b-col cols="4">
                                        <h5>Discord Guild ID: {{ guild.id }}</h5>
                                        <p>Due to Discord's rate limits, we can't display guild name or image-</p>
                                    </b-col>
                                    <b-col cols="2">
                                        
                                    </b-col>
                                    <b-col class="mt-2" cols="5">
                                        <h5>
                                            Permissions
                                        </h5>
                                        <h6>
                                            Power
                                        </h6>
                                        <b-form-group>
                                            <b-form-checkbox-group v-model="profile.discordguilds[index].permissions">
                                              <b-form-checkbox value="service.power.start">Start</b-form-checkbox>
                                              <b-form-checkbox value="service.power.stop">Stop</b-form-checkbox>
                                              <b-form-checkbox value="service.power.kill">Kill</b-form-checkbox>
                                              <b-form-checkbox value="service.power.hibernate">Hibernate</b-form-checkbox>
                                              <b-form-checkbox value="service.power.activate">Activate</b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-form-group>
                                        <h6>
                                            Console
                                        </h6>
                                        <b-form-group>
                                            <b-form-checkbox-group v-model="profile.discordguilds[index].permissions">
                                              <b-form-checkbox value="service.console.update">Update</b-form-checkbox>
                                              <b-form-checkbox value="service.control.command">Send Commands</b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-form-group>
                                        <h6>
                                            Files
                                        </h6>
                                        <b-form-group>
                                            <b-form-checkbox-group v-model="profile.discordguilds[index].permissions">
                                              <b-form-checkbox value="service.files.read">Read</b-form-checkbox>
                                              <b-form-checkbox value="service.files.write">Write</b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </span>
                    </b-tab>-->
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
    .mobilepayButton {
        background-color:#5A78FF;
        border-color:#5A78FF;
        transition: 0.25s;
    }
    .mobilepayButton:hover {
        background-color:#4262f5;
        border-color:#4262f5;
    }

    .billingButton {
        display: inline-block;
        text-align: center;

        margin: 10px;
        min-width: 140px; 
        height: 60px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        h5 {
            display: inline-block;
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
    }

    .selectedBillingButton {
        background-color: #4886FD;
        border-color: #4886FD;
        color: white;
    }

    .billingButton:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px rgb(106, 158, 255);
    }
</style>

<script>
    import {loadStripe} from '@stripe/stripe-js';

    export default {
        name: "settings",
        components: {
            
        },
        props: ["profile", "disks", "services", "lastPayment", "usedBalance"],
        data: () => {
            return {
                stripe: null,
                token: "",
                newProfile: {
                    fullname: "",
                    email: "",
                    company: "",
                },
                billing: {
                    method: "manual",
                    amount: 5
                },
                accepted: false,
                //usedBalance: 0,
                tabIndex: 0,
                tabs: ["#account", "#billing", "#invoices"],
                requestingU2F: false
            }
        },
        async mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");   
            }
            this.getProfile();

            this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash);

            this.stripe = await loadStripe('pk_live_DDmYHSgThTLYwhMKBs3bJ4JB00FTSLyYKr');
        },
        methods: {
            getProfile() {
                this.$http.get(this.StacketConfig.api.profile + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.newProfile = {
                        "fullname": response.data.fullname,
                        "email": response.data.email,
                        "company": response.data.company
                    }
                    this.profile = response.data;
                    if(this.profile.country.toLowerCase() == 'denmark') {
                        this.billing.amount = 30;
                    }
                });
            },
            gotoSetup(){
                window.location.replace("https://auth.stacket.net/2fa?redirect=https://stacket." + this.tld + "/settings");
            },
            purchase() {
                this.$http.post(this.StacketConfig.api.payment + "/", { amount: this.billing.amount}, {headers: {"authorization": this.token}}).then((response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        }) 
                    }
                    this.stripe.redirectToCheckout({
                        sessionId: response.data.id
                    }).then(function (result) {
                        if(result.error){
                            return this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "Payment failed!",
                                text: result.error.message
                            }) 
                        }
                    }).catch(err => {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Payment failed!",
                            text: err.message || err
                        }) 
                    })
                }).catch(err => {
                    var er = "Unable to create transaction session!";
                    if(err.data && err.data.error) {
                        er = err.data.error;
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: er
                    }) 
                })
            },
            purchaseYourPay(method){
                this.$http.post("https://yourpay.stacket.dk/", { amount: this.billing.amount}, {headers: {"authorization": this.token}}).then((response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        }) 
                    }
                    if(response.data.status == "success"){
                        window.open(method == "mobilepay" ? response.data.mobilepay :  response.data.link, 'Stacket Payment - ' + response.data.id)
                    } else {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Payment failed!",
                            text: err.message || err
                        }) 
                    }
                }).catch(err => {
                    var er = "Unable to create transaction session!";
                    if(err.data && err.data.error) {
                        er = err.data.error;
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: er
                    }) 
                })
            },
            downloadGDPR(){
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Downloading GDPR data",
                    text: "Pleae wait..."
                })
                this.$http.get(this.StacketConfig.api.profile + "/gdpr", {headers: {"authorization": this.token}}).then((response) => {
                        if(response.data.error){
                            return this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: response.data.error
                            })
                        }
                        var element = document.createElement('a');
                        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(response.data)));
                        element.setAttribute('download', response.data.profile._id + ".json");

                        element.style.display = 'none';
                        document.body.appendChild(element);

                        element.click();

                        document.body.removeChild(element);
                        return this.$notify({
                            group: "notifications",
                            type: 'success',
                            title: "Downloaded",
                            text: "Successfully downloaded GDPR data!"
                        })
                    }).catch(err => {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Unable to download GDPR data!"
                        })
                    }); 
            },
            updateProfile() {
                if(this.profile.fullname != this.newProfile.fullname || this.profile.email != this.newProfile.email || this.profile.company != this.newProfile.company){
                    this.$http.post(this.StacketConfig.api.profile + "/", {"fullname": this.newProfile.fullname, "email": this.newProfile.email, "company": this.newProfile.company}, {headers: {"authorization": this.token}}).then((response) => {
                        if(response.data.error){
                            return this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: response.data.error
                            })
                        }
                        this.profile.fullname = this.newProfile.fullname.toString();
                        this.profile.email = this.newProfile.email.toString();
                        this.profile.company = this.newProfile.company.toString();
                        return this.$notify({
                                group: "notifications",
                                type: 'success',
                                title: "Updated",
                                text: "Successfully updated profile changes!"
                            })
                    }).catch(err => {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Unable to save profile changes!"
                        })
                    }); 
                }
            },
            getMonthName(date){
                const monthNames = this.Lang.guides.monthnames;
                return monthNames[date.getMonth()];
            }
        }
    }
</script>