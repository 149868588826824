<template>
    <b-container fluid v-if="!this.$route.params.page2">
        <b-row style="margin-top: 25px;">
            <b-col>
                <b-alert variant="primary" show>
                    {{ Lang.dashboard.nodes.note }}
                </b-alert>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="3" v-for="(node, index) of nodes" v-bind:key="index" style="min-width: 375px; margin-bottom: 25px;">
                <nodeCard :nodes="nodes" :node="node" :key="index"></nodeCard>
            </b-col>
        </b-row>
    </b-container>
    <!--<network v-else :teams="teams" :networks="networks" :services="services"></network>-->
</template>

<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>

<script>
import nodeCard from '@/components/nodeCard.vue';
import network from '@/pages/dashboard/network.vue';

export default {
    name: "Nodes",
    props: [
        "teams",
        "services"
    ],
    components: {
        nodeCard
    },
    data: () => {
        return {
            token: "",
            connectId: "",
            additionalIPs: [],
            nodes: [],
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.getNodes();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://dashboard.stacket.dk/&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        getNodes(){
            this.$http.get(this.StacketConfig.api.services + `/nodes`, {headers: {"authorization": this.token}}).then(response => {
                this.nodes = response.data;
            }).catch(err => {
                console.log(err);
            })
        }
    }
}  
</script>