<template>
    <div>
        <b-row class="justify-content-center">
            <b-col cols="9">
                <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;" v-for="(pkg, index) of packages" v-bind:key="index" :class="(service.service.package == index) ? 'packageButton selectedPackageButton' : 'packageButton'" @click="service.service.package = index">
                    <h5>
                        {{ Lang.dashboard.order.priceFormat.replace("$v", (pkg.price * Lang.price).toFixed(2)) }}<small>{{ Lang.dashboard.order.mo }}</small>
                    </h5><br>
                    <span class="ml-2">
                        {{ Lang.dashboard.order.priceFormat.replace("$v", ((pkg.price / 720) * Lang.price).toFixed(4)) }}<small>{{ Lang.dashboard.order.hr }}</small>
                    </span>
                    <hr>
                    <h5>
                        {{ pkg.memory }} MB
                    </h5>
                    <span style="font-size: 1.25rem;">
                        {{ Lang.dashboard.order.ram }}
                    </span><br>
                    <h5>
                        {{ pkg.disk }} GB
                    </h5>
                    <span style="font-size: 1.25rem;">
                        {{ Lang.dashboard.order.disk }}
                    </span>
                </div>
            </b-col>
        </b-row>
        <b-row class="justify-content-center" style="margin-top: 50px;">
            <b-col cols="3">
                <b-button style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; width: 100%; height: 50px;" variant="primary" @click="changePackage()">{{ Lang.dashboard.service.resize.save }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    export default {
        name: "Resize",
        props: [
            "service",
        ],
        data: () => {
            return {
                token: "",
                packages: [],
            }
        },
        mounted() {
            this.token = localStorage.getItem("user-token");
            this.getPackages();
        },
        methods: {
            changePackage(){
                this.$notify({
                    group: "notifications",
                    type: 'warning',
                    title: "Resizing service..",
                    text: "Please wait"
                })
                this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, { package: this.service.service.package}, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Could not resize service.\nTry again later!"
                        })
                    }
                    return this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Service Resized",
                        text: "Successfully resized service!\nRemember to reactivate your service!"
                    })
                });
            },
            getPackages(){
                this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page +"/packages", {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data){
                        this.packages = response.data;
                    } else {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Could not fetch packages.\nTry again later!"
                        })
                    }
                });
            }
        }
    }
</script>