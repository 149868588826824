<template>
    <b-container fluid v-if="!this.$route.params.page4">
        <b-modal id="confirmDelete" title="Delete Network" ok-title="Delete" @ok="deleteNetwork()">
            <p class="my-4" style="text-align:center;">
                Are you sure you want to delete your service?
                <br>
                <b>{{ network.network.name }}</b> will be gone forever (a very long time)!
            </p>
        </b-modal>
        <b-row style="margin-top: 50px; color: rgb(73, 80, 87)!important;">
            <b-col cols="1"></b-col>
            <b-col class="mt-4" style="min-width: 375px; margin-bottom: 25px;">
                <b-row>
                    <b-col cols="2">
                        <router-link to="/networks" style="text-decoration: none;">
                            <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.networks.networks }}</h6>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <h2 v-if="!networkEditing">{{ network.network.name }}</h2>
                        <h2 v-else><b-form-input v-model="network.network.name" placeholder="Enter network name" style="font-size: 2rem; font-weight: 500;"></b-form-input></h2>
                    </b-col>
                    <b-col>
                        <span @click="toggleNetworkEditing()" v-if="!networkEditing"><i class="fas fa-edit editIcon mt-2" style="color:lightgray; font-size: 1.5rem;"></i></span>
                        <span @click="toggleNetworkEditing()" v-if="networkEditing"><i class="fas fa-save saveIcon mt-2" style="color:lightgray; font-size: 1.5rem;"></i></span>
                        <span style="text-align: center;" @click="$bvModal.show('confirmDelete')"><i class="fas fa-trash-alt trashIcon" style="margin-left: 15px; color:lightgray; font-size: 1.5rem"></i></span>
                    </b-col>
                    <b-col cols="3" style="text-align: right;">
                        <router-link :to="'/networks/new/' + this.$route.params.page3 + '/ipaddresses'">
                            <b-button  class="m-2" variant="primary">{{ Lang.dashboard.networks.publicips }}</b-button>
                        </router-link>
                    </b-col>
                    <b-col cols="3">
                        <b-dropdown style="background-color: #28a745; border-radius:5px;"
                        :text="Lang.dashboard.networks.connectService"
                        block
                        variant="primary"
                        class="m-2"
                        menu-class="w-100"
                      >
                        <b-dropdown-item v-for="(service, index) in services" v-bind:key="index" @click="connect(service._id)" style="margin-bottom: 10px;">
                            <span v-for="(folder, id) in folders.filter(e => e.items.find(t => t.id == service._id.toString()))" v-bind:key="id" style="margin-right: 10px; border-radius: 5px; padding: 5px; color: white;" v-bind:style="'background: linear-gradient(0deg,' + folder.color.bottom + ' 0%, ' + folder.color.top + ' 100%);'">
                                {{ folder.name }}
                            </span>
                            {{ serviceNameWithoutFolderName(service) }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p>{{ network.network.ip + network.network.subnet }}</p>
                    </b-col>
                </b-row>
                <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px; min-height: 400px;">
                    <b-row style="margin-top: 15px;">
                        <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;" v-for="(service, index) of services.filter(service => service.networks.find(net => net.id == network.network._id))" v-bind:key="index">
                            <networkServiceCardNew :folders="folders" :service="service" :network="network"></networkServiceCardNew>
                        </b-col>
                        <b-col cols="12" v-if="services.filter(service => service.networks.find(net => net.id == network.network._id)).length == 0">
                            <p style="text-align: center; margin-top: 10%;">
                                No services have been added yet, to get started click connect service.
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
    </b-container>
    <b-container fluid v-else-if="this.$route.params.page4 == 'ipaddresses'">
        <b-modal id="buyip" title="Purchase dedicated IP address" ok-title="Purchase" @ok="buyIpConfirmed()">
            <p class="my-4" style="text-align:center;">
                Are you sure you want to purchase a dedicated ipv4 address for {{ Lang.dashboard.order.priceFormat.replace("$v", (5.719 * Lang.price).toFixed(2)) }}?
            </p>
        </b-modal>
        <b-modal id="cancelip" title="Delete dedicated IP address" ok-title="Delete IP Address" @ok="cancelIpConfirmed()">
            <p class="my-4" style="text-align:center;">
                Are you sure you want to delete your IP Address?
                <br>
                <b>{{ cancelIp }}</b> will be gone forever (a very long time)!
            </p>
        </b-modal>
        <b-row style="margin-top: 50px; color: rgb(73, 80, 87)!important;">
            <b-col cols="1"></b-col>
            <b-col class="mt-4" style="min-width: 375px; margin-bottom: 25px;">
                <b-row>
                    <b-col cols="2">
                        <router-link :to="'/networks/new/' + this.$route.params.page3" style="text-decoration: none;">
                            <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ network.network.name }}</h6>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <h2>{{ Lang.dashboard.networks.publicips }}</h2>
                    </b-col>
                    <b-col></b-col>
                    <b-col cols="2" style="text-align: right;">
                        <b-button class="m-2" @click="buyipModal()" style="width: 100%;" variant="primary">{{ Lang.dashboard.networks.orderip }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p>{{ network.network.name }}</p>
                    </b-col>
                </b-row>
                <div style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #FFFFFF; border-radius: 4px; padding: 20px; min-height: 400px;">
                    <b-row style="margin-top: 15px;">
                        <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;" v-for="(ip, index) of network.network.ipaddresses" v-bind:key="index">
                            <b-card-group deck>
                                <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="shadow-xl serviceCard">
                                    <template v-slot:header>
                                        <span style="color: #777676; font-weight: 500;">
                                            {{ ip.ip }}
                                        </span>
                                    </template>
                                    <b-card-footer style="border-top: none; margin-top: -5px; background-color: transparent; color:#727272; margin-bottom: -5px;">
                                        <b-row>
                                            <b-col style="margin-left: -15px;">
                                                <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                    Datacenter
                                                </h6>
                                            </b-col>
                                            <b-col>
                                                <h6 style="text-align: right; font-size: 13px">
                                                   AAD
                                                </h6>
                                            </b-col>
                                        </b-row>
                                    </b-card-footer>            
                                    <b-card-footer style="margin-top: -5px; background-color: transparent; color:#727272; margin-bottom: -5px;">
                                        <b-row>
                                            <b-col style="margin-left: -15px;">
                                                <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                    Price
                                                </h6>
                                            </b-col>
                                            <b-col>
                                                <h6 style="text-align: right; font-size: 13px">
                                                    {{ Lang.dashboard.order.priceFormat.replace("$v", (5.719 * Lang.price).toFixed(2)) }}
                                                    <span style="font-size:10px;">
                                                        {{ Lang.dashboard.order.mo }}
                                                    </span>
                                                </h6>
                                            </b-col>
                                        </b-row>
                                    </b-card-footer>            
                                    <b-card-footer style="margin-top: -5px; background-color: transparent; color:#727272; margin-bottom: -5px;">
                                        <b-row>
                                            <b-col style="margin-left: -15px;">
                                                <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                    Order Date
                                                </h6>
                                            </b-col>
                                            <b-col>
                                                <h6 style="text-align: right; font-size: 13px">
                                                   {{ dateToYMD(new Date(ip.ordered)) }}
                                                </h6>
                                            </b-col>
                                        </b-row>
                                    </b-card-footer>           
                                    <b-card-footer style="margin-top: -5px; background-color: transparent; color:#727272; margin-bottom: -5px;">
                                        <b-row>
                                            <b-col style="margin-left: -15px;">
                                                <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                    Next Billing Date
                                                </h6>
                                            </b-col>
                                            <b-col>
                                                <h6 style="text-align: right; font-size: 13px">
                                                   {{ dateToYMD(new Date(ip.nextPayment)) }}
                                                </h6>
                                            </b-col>
                                        </b-row>
                                    </b-card-footer>         
                                    <b-card-footer style="margin-top: -5px; background-color: transparent; color:#727272; margin-bottom: -15px;">
                                        <b-row>
                                            <b-col style="text-align:center;">
                                                <b-button variant="danger" @click="cancelipconfirm(ip.ip)">Cancel</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-card-footer>
                                </b-card>
                            </b-card-group>
                        </b-col>
                        <b-col cols="12" v-if="services.filter(service => service.networks.find(net => net.id == network.network._id)).length == 0">
                            <p style="text-align: center; margin-top: 10%;">
                                No IP Addresses have been bought yet, to get started click order ip address.
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">

</style>

<script>
    import networkServiceCardNew from "@/components/networkServiceCardNew.vue";

    export default {
        name: "network",
        components: {
            networkServiceCardNew
        },
        data: () => {
            return {
                token: "",
                network: {},
                connectId: "",
                networkEditing: false,
                cancelIp: "",
            }
        },
        props: [
            "teams",
            "networks",
            "services",
            "folders"
        ],
        mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");

                this.getNetwork();
            }
        },
        methods: {
            buyipModal(){
                this.$bvModal.show("buyip");
            },
            buyIpConfirmed(){
                let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
                this.$http.get(this.StacketConfig.api.networks + "/new/" + this.$route.params.page3 + "/buyip", {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        loader.hide();
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.getNetwork();
                    loader.hide();
                }).catch(err => {
                    loader.hide();
                    if(err.body && err.body.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.body.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not purchase dedicated ip address!"
                    })
                });
            },
            cancelipconfirm(ip){
                this.cancelIp = ip;
                this.$bvModal.show("cancelip");
            },
            cancelIpConfirmed(){
                let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
                this.$http.delete(this.StacketConfig.api.networks + "/new/" + this.$route.params.page3 + "/ip/" + this.cancelIp, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        loader.hide();
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.getNetwork();
                    loader.hide();
                    this.cancelIp = "";
                }).catch(err => {
                    loader.hide();
                    this.cancelIp = "";
                    if(err.body && err.body.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.body.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not delete dedicated ip address!"
                    })
                });
            },
            dateToYMD(date) {
                var d = date.getDate();
                var m = date.getMonth() + 1; //Month from 0 to 11
                var y = date.getFullYear();
                return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
            },
            serviceNameWithoutFolderName(service){
                var name = service.name;
                var folders = this.folders.filter(e => e.items.find(t => t.id == service._id));
                for(var folder of folders){
                    name = name.replace(folder.name + " - ", "").replace(folder.name, "")
                }
                return name;
            },
            getNetwork() {
                let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
                this.$http.get(this.StacketConfig.api.networks + "/new/" + this.$route.params.page3, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        loader.hide();
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.network = response.data;
                    loader.hide();
                }).catch(err => {
                    loader.hide();
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch network!"
                    })
                });
            },
            deleteNetwork(){
                let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
                this.$http.delete(this.StacketConfig.api.networks + `/new/${this.network.network._id}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    loader.hide();
                    this.$parent.$parent.getNetworks();
                    this.$router.replace('/networks');
                }).catch(err => {
                    loader.hide();
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not delete network!"
                    });
                })
            },
            connect(service) {
                console.log("Connecting " + service + ", please wait...");
                let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
                var network = this.network.network._id;
                this.$http.get(this.StacketConfig.api.networks + `/new/${network}/connect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.getNetwork();
                    this.$parent.$parent.getServices();
                    this.$parent.$parent.getNetworks();
                    loader.hide();
                }).catch(err => {
                    loader.hide();
                    if(err.body && err.body.error){
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.body.error
                        });
                    }
                })
            },
            disconnect(network, service) {
                let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
                console.log("Disconnecting " + service + ", please wait...");
                this.$http.get(this.StacketConfig.api.networks + `/new/${network}/disconnect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.getNetwork();
                    this.$parent.$parent.getServices();
                    this.$parent.$parent.getNetworks();
                    loader.hide();
                }).catch(err => {
                    loader.hide();
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not disconnect service!"
                    });
                })
            },
            toggleNetworkEditing() {
                if(this.networkEditing == true) {
                    this.networkEditing = !this.networkEditing;
                    this.$http.post(this.StacketConfig.api.networks + `/new/${this.network.network._id}`, {name: this.network.network.name}, {headers: {"authorization": this.token}}).then(response => {
                        if(response.data.error) {
                            this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: response.data.error
                            });
                        }
                        this.getNetwork();
                        this.$parent.$parent.getServices();
                        this.$parent.$parent.getNetworks();
                    }).catch(err => {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Could not update network!"
                        });
                    });
                } else {
                    this.networkEditing = !this.networkEditing;
                }
            }
        }
    }
</script>