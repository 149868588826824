<template>
    <b-container fluid v-if="!this.$route.params.page2">
        <b-row style="margin-top: 25px;">
            <b-col>
                <b-alert variant="primary" show>
                    Open a Support Ticket to create a team
                </b-alert>
            </b-col>
        </b-row>
        <b-row v-bind:key="index">
            <router-link tag="span" v-for="(team, index) of teams" v-bind:key="index" :to="'/teams/' + team._id">
                <b-col cols="3" style="cursor: pointer; min-width: 375px; margin-bottom: 25px;">
                    <b-card-group deck class="mt-3">
                        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
                            <template v-slot:header>
                                <h6 class="mb-0" style="color: #777676;">
                                    <span style="color: #777676;">{{ team.name }}</span>
                                </h6>
                            </template>
                            <b-card-body>
                                <b-row style="margin-bottom: 5px;">
                                    <b-col>
                                        <center>
                                            <img v-bind:src="team.image" style="width: 50px;" v-b-tooltip.hover v-bind:title="team.name">
                                        </center>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <center>
                                            <h6>
                                                {{ Lang.dashboard.components.service.team }}
                                            </h6>
                                        </center>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                <b-row>
                                    <b-col style="margin-left: -15px;">
                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                            Members
                                        </h6>
                                    </b-col>
                                    <b-col>
                                        <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                                            {{ Object.keys(team.members).length }}
                                        </h6>
                                    </b-col>
                                </b-row>
                            </b-card-footer>
                        </b-card>
                    </b-card-group>
                </b-col>
            </router-link>
        </b-row>
    </b-container>
    <team v-else :teams="teams" :networks="networks" :services="services" :disks="disks"></team>
</template>

<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>

<script>
//import networkCard from '@/components/networkCard.vue';
import team from '@/pages/dashboard/team.vue';

export default {
    name: "Teams",
    props: [
        "teams",
        "networks",
        "services",
        "disks"
    ],
    components: {
        team
    },
    data: () => {
        return {
            token: ""
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    //
                   // this.getIPs();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://dashboard.stacket.dk/&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
    }
}  
</script>