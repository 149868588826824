<template>
    <b-container fluid>
        <b-row align-h="end">
            <b-col v-if="service.service && service.service.type == 'csgo'">
                <b-input-group>
                    <b-button variant="primary" @click="updateService(false)" style="margin-right: 25px;">{{ Lang.dashboard.service.console.update }}</b-button>
                    <b-button variant="primary" @click="updateService(true)">{{ Lang.dashboard.service.console.updateValidate }}</b-button>
                </b-input-group>
            </b-col>
            <b-col v-if="service.service && service.service.type == 'nodejs'">
                <b-input-group>
                    <b-button variant="primary" @click="npminstallService('')" style="margin-right: 25px;">{{ Lang.dashboard.service.console.npmInstall }}</b-button>
                </b-input-group>
            </b-col>
            <b-col class="ml-5">
                <i class="fas fa-circle" style="color:#2ead42!important;" v-if="service.status == 'online'"></i>
                <i class="fas fa-circle" style="color:#f13862!important;" v-else-if="service.status == 'offline'"></i>
                <i class="fas fa-circle" v-else-if="service.status == 'hibernating'"></i>
                <strong class="ml-3" style="color:#2ead42!important;" v-if="service.status == 'online'">{{ Lang.dashboard.service.online }}</strong>
                <strong class="ml-3" style="color:#f13862!important;" v-else-if="service.status == 'offline'">{{ Lang.dashboard.service.offline }}</strong>
                <strong class="ml-3" v-else-if="service.status == 'hibernating'">{{ Lang.dashboard.service.hibernate }}</strong>
            </b-col>
            <b-button class="mr-2" variant="success" v-on:click="$parent.startService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.start }}</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.stopService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.stop }}</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.killService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.kill }}</b-button>
            <b-button class="mr-2" variant="primary" v-on:click="$parent.hibernateService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.hibernate }}</b-button>
            <b-button class="mr-5" variant="warning" v-on:click="$parent.reactivateService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.reactivate }}</b-button>
        </b-row>
        <hr>
        <b-row>
            <b-col>
                <div id="xterm" style="border-radius: 5px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; padding: 10px; background-color: #222;"></div>
                <!--<log-viewer style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;" :log="log" :loading="isLoading" />-->
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                <p>
                    {{ Lang.dashboard.service.console.executeCommand }}
                </p>
                <b-input-group prepend="/" class="mt-3">
                    <b-form-input v-model="command" @keyup.enter="executeCommand()"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="primary" @click="executeCommand">{{ Lang.dashboard.service.console.execute }}</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
 
</script>
<style lang="scss">
    
</style>
<script>

const io = require("socket.io-client")

import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { SearchAddon } from 'xterm-addon-search';
import { SearchBarAddon } from 'xterm-addon-search-bar';
import "xterm/css/xterm.css";
import "xterm/lib/xterm.js";

export default {
    name: "Console",
    components: {
        
    },
    data: () => {
        return {
            token: "",
            service: {},
            command: "",
            socket: null,
            isLoading: false,
            term: null
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        var Vue = this;
        Vue.initXterm();
        setTimeout(function(){
            Vue.service = Vue.$parent.service;

            Vue.socket = io(`https://console_devapi.stacket.net:2083/service?serviceId=${Vue.$route.params.page}&authorization=${Vue.token}`, {
                "transports": ["websocket"]
            });

            Vue.socket.connect();

            //socket io events
            Vue.term.writeln("Connecting to console API...");
            Vue.socket.on('connect', function(){
                console.log("Connected to console api")
                Vue.term.writeln("Connected to console API")
            });
            Vue.socket.on('console', function(data) {
                console.log("Received console")
                Vue.term.writeln(data.toString().trim())
            });
            Vue.socket.on('disconnect', function(){
                console.log("Disconnected from console api")
                Vue.term.writeln("Disconnected from console API!")
            });
        }, 1000);
    },
    beforeDestroy(){
        if(this.socket != null) this.socket.disconnect();
    },
    methods: {
        initXterm() {
            this.term = new Terminal({
                rendererType: "canvas", //Rendering type
                rows: 35, //Rows 
                convertEol: true, //When enabled, the cursor will be set to the beginning of the next line
                scrollback: 100, //The amount of rollback in the terminal
                disableStdin: true, //Whether input should be disabled
                cursorStyle: "none", //Cursor style
                cursorBlink: false, //Cursor blinks
                theme: {
                    foreground: "white", //Font
                    background: "#222", //Background color
                    cursor: "help" //Set cursor
                }
            });
            const searchAddon = new SearchAddon();
            const searchBar = new SearchBarAddon({ searchAddon });
            const fitAddon = new FitAddon();
            this.term.loadAddon(fitAddon);
            this.term.loadAddon(searchAddon)
            this.term.loadAddon(searchBar)
            this.term.open(document.getElementById("xterm"));
            fitAddon.fit();
            //  Support input and paste methods
            let _this = this; //Must redefine onethis,otherwisethisPointing will go wrong
            this.term.attachCustomKeyEventHandler((e) => {
                console.log(e)
                if(e.type != "keydown") return;
                if (e.ctrlKey && e.key === 'c') {
                    document.execCommand('copy');
                    return true;
                }
                if (e.ctrlKey && e.key === 'f') {
                    console.log("key")
                    searchBar.show();
                    return true;
                }
                if (e.key === 'Escape') {
                    console.log(searchBar)
                    searchBar.hidden();
                    return true;
                }
                return false;
            });
            window.addEventListener("resize", function() {
                fitAddon.fit()
            })
        },
        npminstallService(packages){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {"state": "npmInstall", "packages": packages}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Installing packages",
                    text: "Please wait.."
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not install packages!"
                })
            });
        },
        updateService(validate){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "update", validate: validate}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Updating Service",
                    text: "Please wait.."
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not update service!"
                })
            });
        },
        executeCommand(){
            if(this.command.toString().length == 0) return;

            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "console", command: this.command}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.command = "";
                this.$notify({
                    group: "notifications",
                    type: 'warning',
                    title: "Executing command",
                    text: "Please wait"
                });
                var Vue = this;
                var interval = setInterval(async function() {
                    Vue.$http.get(Vue.StacketConfig.api.services + "/" + Vue.$route.params.page + "/actions/" + response.data._id, {headers: {"authorization": Vue.token}}).then(async (status) => {
                        if(status.data.error){
                            window.clearInterval(interval);        
                            return Vue.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: status.data.error
                            })
                        }
                        if(status.data.status == "success"){  
                            window.clearInterval(interval);                   
                            return Vue.$notify({
                                group: "notifications",
                                type: 'success',
                                title: "Success!",
                                text: "Command has been executed"
                            });
                        } else if(status.data.status == "error"){
                            window.clearInterval(interval);                   
                            return Vue.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "Error!",
                                text: status.data.error.message
                            })
                        }
                    }).catch(err => {
                        Vue.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Could not fetch action!"
                        })
                    });
                }, 1000);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not execute command!"
                })
            });
        },
    }
}  
</script>