<template>
  <div id="app">
    <notifications group="notifications" position="bottom right"/>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {

  },
  watch: {
    '$route': {
      handler: (to, from) => {
        if (to.name) {
          document.title = 'Stacket - ' + to.name
        } else {
          document.title = 'Stacket'
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
$theme-colors: (
  "primary": #4886FD,
  "warning": #e97d31,
  "dark": #071a2b,
  "discord": #7289DA,
  "google": #DB4437,
  "github": #222222,
  "stacketblue": #026DC9,
  "offwhite": #F6F7FB
);

.btn-warning {
  color: rgb(248, 231, 218)!important;
}

.btn {
  border-radius: 3px!important;
}

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';

@import "https://use.fontawesome.com/releases/v5.12.0/css/all.css";

#app {
  height: 100%;
}

html, body {
  height: 100%;
}
</style>
