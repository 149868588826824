<template>
    <b-card-group deck v-if="service.team">
        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5; box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;" class="serviceCard">
            <template v-slot:header>
                <div @click="goToService()" style="cursor: pointer; text-decoration: none;">
                    <h6 class="mb-0" style="color: #777676;">
                        <a style="text-decoration: none;" :href="'/' + service._id + '/overview'">
                            <span v-for="(folder, id) in folders.filter(e => e.items.find(t => t.id == service._id.toString()))" v-bind:key="id" style="margin-left: -10px; margin-right: 10px; border-radius: 5px; padding: 5px; color: white;" v-bind:style="'background: linear-gradient(0deg,' + folder.color.bottom + ' 0%, ' + folder.color.top + ' 100%);'">
                                {{ folder.name }}
                            </span>
                            <span style="color: #777676;">{{ service.team.name }} - {{ service.name }}</span>
                        </a>
                        <i class="fas fa-circle" style="color:#2ead42!important; float: right;" v-b-tooltip.hover v-bind:title="Lang.dashboard.service.online" v-if="service.status == 'online'"></i>
                        <i class="fas fa-circle" style="color:#f13862!important; float: right;" v-b-tooltip.hover v-bind:title="Lang.dashboard.service.offline" v-else-if="service.status == 'offline'"></i>
                        <i class="fas fa-circle" style="color:#c9c8c8!important; float: right;" v-b-tooltip.hover v-bind:title="Lang.dashboard.service.hibernating" v-else-if="service.status == 'hibernating'"></i>
                    </h6>
                </div>
            </template>
            <b-card-body v-if="service.team.groups && service.team.groups.length != 0">
                <b-row style="margin-bottom: 5px;">
                    <b-col cols="4">
                        <center>
                            <img v-bind:src="service.team.image" style="width: 50px;" v-b-tooltip.hover v-bind:title="service.team.name">
                        </center>
                    </b-col>
                    <b-col cols="1">
                        <center style="height: 100%;">
                            <div style="height: 100%; border: #e2e2e4 solid 0.5px;"></div>
                        </center>
                    </b-col>
                    <b-col style="padding-top: 10px;">
                        <span v-html="convertName(service.team.groups[0].name || '')" v-if="service.team.groups[0]" v-b-tooltip.hover v-bind:title="service.team.groups[0].name" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #eaf8eb; font-weight: 500; font-size: 12px; color: #2ead42; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px;">
                            
                        </span>
                        <span v-html="convertName(service.team.groups[1].name || '')" v-if="service.team.groups[1]" v-b-tooltip.hover v-bind:title="service.team.groups[1].name" class="align-middle shadow-sm" style="text-transform: uppercase; text-align center; background-color: #f0f3fa; font-weight: 500; font-size: 12px; color: #477ed9; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                        <span v-html="'+'+(service.team.groups.length - 2)" v-if="service.team.groups[2]" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #ffeff1; font-weight: 500; font-size: 13px; color: #f13862; padding: 9px; padding-top: 9px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <center>
                            <h6>
                                {{ Lang.dashboard.components.service.team }}
                            </h6>
                        </center>
                    </b-col>
                    <b-col>
                        <center>
                            <h6>
                                {{ Lang.dashboard.components.service.groups }}
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-else="">
                <b-row style="margin-bottom: 5px;">
                    <b-col>
                        <center>
                            <img v-bind:src="service.team.image" style="width: 50px;" v-b-tooltip.hover v-bind:title="service.team.name">
                        </center>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <center>
                            <h6>
                                {{ Lang.dashboard.components.service.team }}
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ service.type }} {{ Lang.dashboard.components.service.hosting }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px" v-if="pricing && pricing[service.package] && pricing[service.package].price">
                            {{ Lang.dashboard.order.priceFormat.replace("$v", (pricing[service.package].price * Lang.price).toFixed(2)) }}
                            <span style="font-size:10px;">
                                {{ Lang.dashboard.order.mo }}
                            </span>
                        </h6>
                        <h6 style="text-align: right; font-size: 13px" v-else-if="service.price">
                            {{ Lang.dashboard.order.priceFormat.replace("$v", (service.price * Lang.price).toFixed(2)) }}
                            <span style="font-size:10px;">
                                {{ Lang.dashboard.order.mo }}
                            </span>
                        </h6>
                        <h6 style="text-align: right; font-size: 13px" v-else="">
                            {{ Lang.dashboard.components.service.loadingPrices }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -20px;">
                <b-row>
                    <router-link :to="'/'+service._id+'/subusers'">
                        <b-col style="text-align: center;">
                            <i class="fas fa-user-plus productIcons" v-b-tooltip.hover v-bind:title="Lang.dashboard.components.service.manageSubusers"></i>
                        </b-col>
                    </router-link>
                    <b-col style="text-align: center;" @click="star(index, service._id)">
                        <i class="fas fa-star productIcons" style="color:#FED256!important;" v-if="$parent.$parent.user && $parent.$parent.user.starred && $parent.$parent.user.starred.includes(service._id)"></i>
                        <i class="fas fa-star productIcons" v-else=""></i>
                    </b-col>
                    <b-col style="text-align: center;">
                        <i class="fas fa-cloud-download-alt productIcons" v-b-tooltip.hover v-bind:title="Lang.dashboard.components.service.notAvailableYet"></i>
                    </b-col>
                    <b-col style="text-align: center;">
                        <i class="fas fa-info-circle productIcons" v-b-tooltip.hover v-bind:title="Lang.dashboard.components.service.notAvailableYet"></i>
                    </b-col>
                    <b-col style="text-align: center;">
                        <b-dropdown size="sm" dropup right variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <i class="fas fa-ellipsis-v productIcons" style="font-size: 17.5px;"></i>
                            </template>
                            <b-dropdown-header id="dropdown-header-label">
                                Service Settings
                            </b-dropdown-header>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item @click="r$parent.$parent.renameServiceModal(service._id)">Rename Service</b-dropdown-item>
                            <b-dropdown-item @click="$parent.$parent.migrateStart(service._id)">Migrate Service</b-dropdown-item>
                            <b-dropdown-item @click="$parent.$parent.deleteServiceModal(service._id, service.team.name +' - ' +service.name)">Delete Service</b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-card-group>
    <b-card-group deck v-else="">
        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="shadow-xl serviceCard">
            <template v-slot:header>
                <div @click="goToService()" style="cursor: pointer; text-decoration: none;">
                    <h6 class="mb-0" style="color: #777676;">
                        <a style="text-decoration: none;" :href="'/' + service._id + '/overview'">
                            <span v-for="(folder, id) in folders.filter(e => e.items.find(t => t.id == service._id.toString()))" v-bind:key="id" style="margin-left: -10px; margin-right: 10px; border-radius: 5px; padding: 5px; color: white;" v-bind:style="'background: linear-gradient(0deg,' + folder.color.bottom + ' 0%, ' + folder.color.top + ' 100%);'">
                                {{ folder.name }}
                            </span>
                            <span style="color: #777676;">{{ service.name }}</span>
                        </a>
                        <i class="fas fa-circle" style="color:#2ead42!important; float: right;" v-if="service.status == 'online'"></i>
                        <i class="fas fa-circle" style="color:#f13862!important; float: right;" v-else-if="service.status == 'offline'"></i>
                        <i class="fas fa-circle" style="color:#c9c8c8!important; float: right;" v-else-if="service.status == 'hibernating'"></i>
                    </h6>
                </div>
            </template>
            <b-card-body v-if="service.subusers && service.subusers.length != 0">
                <b-row style="margin-bottom: 5px;">
                    <b-col cols="4">
                        <center>
                            <img v-bind:src="service.owner.image" style="width: 50px; border-radius: 100%;" v-b-tooltip.hover v-bind:title="service.owner.fullname">
                        </center>
                    </b-col>
                    <b-col cols="1">
                        <center style="height: 100%;">
                            <div style="height: 100%; border: #e2e2e4 solid 0.5px;"></div>
                        </center>
                    </b-col>
                    <b-col style="padding-top: 10px;">
                        <span v-html="convertName(service.subusers[0].fullname || '')" v-if="service.subusers[0]" v-b-tooltip.hover v-bind:title="service.subusers[0].fullname" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #eaf8eb; font-weight: 500; font-size: 12px; color: #2ead42; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px;">
                            
                        </span>
                        <span v-html="convertName(service.subusers[1].fullname || '')" v-if="service.subusers[1]" v-b-tooltip.hover v-bind:title="service.subusers[1].fullname" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #f0f3fa; font-weight: 500; font-size: 12px; color: #477ed9; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                        <span v-html="'+'+(service.subusers.length - 2)" v-if="service.subusers[2]" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #ffeff1; font-weight: 500; font-size: 13px; color: #f13862; padding: 9px; padding-top: 9px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 15px;">
                    <b-col cols="4">
                        <center>
                            <h6>
                                {{ Lang.dashboard.components.service.owner }}
                            </h6>
                        </center>
                    </b-col>
                    <b-col>
                        <center>
                            <h6>
                                {{ Lang.dashboard.components.service.sharedPeople }}
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-else="">
                <b-row style="margin-bottom: 15px;">
                    <b-col>
                        <center>
                            <img v-bind:src="service.owner.image" style="width: 50px; border-radius: 100%;" v-b-tooltip.hover v-bind:title="service.owner.fullname">
                        </center>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <center>
                            <h6>
                                {{ Lang.dashboard.components.service.owner }}
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ service.type }} {{ Lang.dashboard.components.service.hosting }}
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px" v-if="pricing && pricing[service.package] && pricing[service.package].price">
                            {{ Lang.dashboard.order.priceFormat.replace("$v", (pricing[service.package].price * Lang.price).toFixed(2)) }}
                            <span style="font-size:10px;">
                                {{ Lang.dashboard.order.mo }}
                            </span>
                        </h6>
                        <h6 style="text-align: right; font-size: 13px" v-else-if="service.price">
                            {{ Lang.dashboard.order.priceFormat.replace("$v", (service.price * Lang.price).toFixed(2)) }}
                            <span style="font-size:10px;">
                                {{ Lang.dashboard.order.mo }}
                            </span>
                        </h6>
                        <h6 style="text-align: right; font-size: 13px" v-else="">
                            {{ Lang.dashboard.components.service.loadingPrices }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -20px;">
                <b-row>
                    <router-link :to="'/'+service._id+'/subusers'">
                        <b-col style="text-align: center;">
                            <i class="fas fa-user-plus productIcons" v-b-tooltip.hover v-bind:title="Lang.dashboard.components.service.manageSubusers"></i>
                        </b-col>
                    </router-link>
                    <b-col style="text-align: center;" @click="star(index, service._id)">
                        <i class="fas fa-star productIcons" style="color:#FED256!important;" v-if="$parent.$parent.user && $parent.$parent.user.starred && $parent.$parent.user.starred.includes(service._id)"></i>
                        <i class="fas fa-star productIcons" v-else=""></i>
                    </b-col>
                    <b-col style="text-align: center;">
                        <i class="fas fa-cloud-download-alt productIcons" v-b-tooltip.hover v-bind:title="Lang.dashboard.components.service.notAvailableYet"></i>
                    </b-col>
                    <b-col style="text-align: center;">
                        <i class="fas fa-info-circle productIcons" v-b-tooltip.hover v-bind:title="Lang.dashboard.components.service.notAvailableYet"></i>
                    </b-col>
                    <b-col style="text-align: center;">
                        <b-dropdown size="sm" dropup right variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <i class="fas fa-ellipsis-v productIcons" style="font-size: 17.5px;"></i>
                            </template>
                            <b-dropdown-header id="dropdown-header-label">
                                Quick Settings
                            </b-dropdown-header>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item @click="$parent.$parent.renameServiceModal(service._id)">Rename</b-dropdown-item>
                            <b-dropdown-item @click="($parent.$parent.migrateStart ? $parent.$parent.migrateStart(service._id) : $parent.$parent.$parent.migrateStart(service._id))">Migrate</b-dropdown-item>
                            <b-dropdown-item @click="$parent.$parent.deleteServiceModal(service._id, service.name)">Delete</b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-card-group>
</template>

<style lang="scss">
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>

<script>

export default {
    name: "serviceCard",
    components: {
        
    },
    data: () => {
        return {
            token: "",
            pricing: {},
        }
    },
    props: [
        "service",
        "folders"
    ],
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
        }
        if(!this.service.price){
            this.getPricingService();
        }
    },
    methods: {
        goToService(){
            if(this.service.type == "vps" && this.service.esxi){
                window.open(`https://vmware.stacket.net:2053/`, this.service._id)
            } else {
                this.$router.push("/" + this.service._id + "/overview")
            }
        },
        async getPricingService() {
            var pricing = await this.$http.get(this.StacketConfig.api.services + `/${this.service._id}/packages`, {headers: {"authorization": this.token}});
            this.pricing = pricing.data;
        },
        convertName(name) {
            var fullname = name+"";
            var split = fullname.split(" ");
            if(split.length == 1){
                return split[0].charAt(0) + split[0].charAt(1);
            } else {
                var firstName = split[0];
                var lastName = split[split.length-1];

                return firstName.charAt(0) + lastName.charAt(0);
            }

        },
        star(index, serviceId) {
            this.$http.post(this.StacketConfig.api.profile + "/star", {service: serviceId}, {headers: {"authorization": this.token}}).then((response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$parent.$parent.getProfile();
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not star/unstar service!"
                })
            });
        },
    }
}  

</script>