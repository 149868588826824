<template>
    <b-container fluid>
        <b-row style="margin-top: 25px;">
            <b-col>
                <h4>
                    Statistics
                </h4>
            </b-col>
        </b-row>
        <b-row style="margin-bottom: 0;">
            <b-col>
                <p style="margin-bottom: 0;">
                    <b>Services Active: </b>
                    {{ services.filter(srv => srv.status != 'hibernating').length }} / {{ services.length }}
                </p>
                <p style="margin-bottom: 0;">
                    <b>Allocated RAM: </b>
                    {{ usage.map(n => allocated(n.node)).reduce((a, b) => a + b, 0) }} GB / {{ (usage.map(e => e.memory).reduce((a, b) => ({"total": a.total + b.total})).total / 1024).toFixed(2) }} GB
                </p>
                <p style="margin-bottom: 0;">
                    <b>Avg CPU Usage: </b>
                    {{ (usage.reduce((a, b) => ({cpu: a.cpu + b.cpu})).cpu / usage.length).toFixed(4) }} %
                </p>
                <p style="margin-bottom: 0;">
                    <b>Total RAM Usage: </b>
                    {{ (usage.map(e => e.memory).reduce((a, b) => ({"used": a.used + b.used})).used / 1024).toFixed(4) }} GB
                </p>
            </b-col>
        </b-row>
        <hr>
        <b-row style="margin-top: 25px;">
            <b-col>
                <h5>
                    Aalborg
                    <img src="@/assets/images/services/denmark.png" style="height: 25px; margin-left: 10px;">
                </h5>
            </b-col>
            <b-col>
                <h5>
                    Falkenstein
                    <img src="@/assets/images/services/germany.png" style="height: 25px; margin-left: 10px;">
                </h5>
            </b-col>
        </b-row>
        <b-row style="margin-bottom: 0;">
            <!-- Aalborg -->
            <b-col>
                <p style="margin-bottom: 0;">
                    <b>Services Active: </b>
                    {{ services.filter(srv => srv.node.startsWith("aad")).filter(srv => srv.status != 'hibernating').length }} / {{ services.filter(srv => srv.node.startsWith("aad")).length }}
                </p>
                <p style="margin-bottom: 0;">
                    <b>Allocated RAM: </b>
                    {{ usage.filter(srv => srv.node.startsWith("aad")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0) }} GB / {{ (usage.filter(srv => srv.node.startsWith("aad")).map(e => e.memory).reduce((a, b) => ({"total": a.total + b.total})).total / 1024).toFixed(2) }} GB
                </p>
                <p style="margin-bottom: 0;">
                    <b>Avg CPU Usage: </b>
                    {{ (usage.filter(srv => srv.node.startsWith("aad")).reduce((a, b) => ({cpu: a.cpu + b.cpu})).cpu / usage.filter(srv => srv.node.startsWith("aad")).length).toFixed(4) }} %
                </p>
                <p style="margin-bottom: 0;">
                    <b>Total RAM Usage: </b>
                    {{ (usage.filter(srv => srv.node.startsWith("aad")).map(e => e.memory).reduce((a, b) => ({"used": a.used + b.used})).used / 1024).toFixed(4) }} GB
                </p>
                <p style="margin-bottom: 0;">
                    <b>Earnings (Hour): </b>
                    ${{ (((usage.filter(srv => srv.node.startsWith("aad")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0)) * 2.78 ) / 730).toFixed(4) }} ({{ ((((usage.filter(srv => srv.node.startsWith("aad")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0)) * 2.78 ) * 6.40 ) / 730).toFixed(2) }} dkk)
                </p>
                <p style="margin-bottom: 0;">
                    <b>Earnings (Month): </b>
                    ${{ ((usage.filter(srv => srv.node.startsWith("aad")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0)) * 2.78 ).toFixed(4) }} ({{ (((usage.filter(srv => srv.node.startsWith("aad")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0)) * 2.78 ) * 6.40 ).toFixed(2) }} dkk)
                </p>
            </b-col>
            <!-- Falkenstein -->
            <b-col>
                <p style="margin-bottom: 0;">
                    <b>Services Active: </b>
                    {{ services.filter(srv => srv.node.startsWith("fsn")).filter(srv => srv.status != 'hibernating').length }} / {{ services.filter(srv => srv.node.startsWith("fsn")).length }}
                </p>
                <p style="margin-bottom: 0;">
                    <b>Allocated RAM: </b>
                    {{ usage.filter(srv => srv.node.startsWith("fsn")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0) }} GB / {{ (usage.filter(srv => srv.node.startsWith("fsn")).map(e => e.memory).reduce((a, b) => ({"total": a.total + b.total})).total / 1024).toFixed(2) }} GB
                </p>
                <p style="margin-bottom: 0;">
                    <b>Avg CPU Usage: </b>
                    {{ (usage.filter(srv => srv.node.startsWith("fsn")).reduce((a, b) => ({cpu: a.cpu + b.cpu})).cpu / usage.filter(srv => srv.node.startsWith("fsn")).length).toFixed(4) }} %
                </p>
                <p style="margin-bottom: 0;">
                    <b>Total RAM Usage: </b>
                    {{ (usage.filter(srv => srv.node.startsWith("fsn")).map(e => e.memory).reduce((a, b) => ({"used": a.used + b.used})).used / 1024).toFixed(4) }} GB
                </p>
                <p style="margin-bottom: 0;">
                    <b>Earnings (Hour): </b>
                    ${{ (((usage.filter(srv => srv.node.startsWith("fsn")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0)) * 2 ) / 730).toFixed(2) }} ({{ ((((usage.filter(srv => srv.node.startsWith("fsn")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0)) * 2 ) * 6.40 ) / 730).toFixed(2) }} dkk)
                </p>
                <p style="margin-bottom: 0;">
                    <b>Earnings (Month): </b>
                    ${{ ((usage.filter(srv => srv.node.startsWith("fsn")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0)) * 2 ).toFixed(2) }} ({{ (((usage.filter(srv => srv.node.startsWith("fsn")).map(n => allocated(n.node)).reduce((a, b) => a + b, 0)) * 2 ) * 6.40 ).toFixed(2) }} dkk)
                </p>
            </b-col>
        </b-row>
        <b-row style="margin-top: 25px; margin-bottom: 0;">
            <b-col>
                <h4>
                    Nodes
                </h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="usage.length == 0">
                <p>
                    Loading node status..
                </p>
            </b-col>
            <b-col v-else>
                <b-row>
                    <b-col cols="2" v-for="(node, index) of usage" v-bind:key="index" style="min-width: 375px; margin-bottom: 25px;">
                        <b-card-group deck class="mt-3">
                            <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
                                <template v-slot:header>
                                    <h6 class="mb-0" style="color: #777676;">
                                        <span style="color: #777676;">{{ node.node }}</span>
                                    </h6>
                                </template>
                                <b-card-footer style="border-top-color: transparent; background-color: transparent; color:#727272; margin-bottom: -5px; margin-top: -10px;">
                                    <b-row>
                                        <b-col style="margin-left: -15px;">
                                            <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                Status
                                            </h6>
                                        </b-col>
                                        <b-col>
                                            <h6 style="text-align: right; font-size: 13px;">
                                                {{ nodeStatus(node)  }} ({{ percentageFull(node).toFixed(2) }} %)
                                            </h6>
                                        </b-col>
                                    </b-row>
                                </b-card-footer>
                                <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                    <b-row>
                                        <b-col style="margin-left: -15px;">
                                            <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                CPU Usage
                                            </h6>
                                        </b-col>
                                        <b-col>
                                            <h6 style="text-align: right; font-size: 13px">
                                                {{ node.cpu.toFixed(4) }}%
                                            </h6>
                                        </b-col>
                                    </b-row>
                                </b-card-footer>
                                <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                    <b-row>
                                        <b-col style="margin-left: -15px;">
                                            <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                Memory Usage
                                            </h6>
                                        </b-col>
                                        <b-col>
                                            <h6 style="text-align: right; font-size: 13px">
                                                {{ (node.memory.used / 1024).toFixed(2) }} / {{ (node.memory.total / 1024).toFixed(2) }} GB
                                            </h6>
                                        </b-col>
                                    </b-row>
                                </b-card-footer>
                                <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                    <b-row>
                                        <b-col style="margin-left: -15px;">
                                            <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                Memory Allocated
                                            </h6>
                                        </b-col>
                                        <b-col>
                                            <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                                                {{ allocated(node.node) }} GB
                                            </h6>
                                        </b-col>
                                    </b-row>
                                </b-card-footer>
                                <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                    <b-row>
                                        <b-col style="margin-left: -15px;">
                                            <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                Services Active
                                            </h6>
                                        </b-col>
                                        <b-col>
                                            <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                                                {{ services.filter(srv => srv.node == node.node && srv.status != 'hibernating').length }} / {{ services.filter(srv => srv.node == node.node).length }}
                                            </h6>
                                        </b-col>
                                    </b-row>
                                </b-card-footer>
                            </b-card>
                        </b-card-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

</script>
<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>
<script>
import serviceCard from '@/components/serviceCard.vue';

export default {
    name: "Staff",
    components: {
        serviceCard
    },
    data: () => {
        return {
            token: "",
            services: [],
            usage: [],
            earnings: {
                services: 0,
                amount: 0.00,
                additional: 0.00,
                dedicatedservers: 0.00,
            },
            status: {},

        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.doRequests();
                } else {
                    localStorage.removeItem("user-token");
                    return window.location.replace("https://auth.stacket.net?redirect=https://stacket." + this.tld + "/dashboard&response_type=token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    cron: {
        method: 'getStaffUsage',
        time: 10000
    },
    methods: {
        nodeStatus(node) {
            var percentage = this.percentageFull(node);
            if(percentage < 40){
                return "Low";
            } else if(percentage < 75){
                return "Medium"; 
            } else if(percentage < 95){
                return "High";
            } else if(percentage < 100){
                return "Sold Out";
            } else {
                return "Sold Out";
            }
        },
        percentageFull(node){
            return (((this.allocated(node.node) * 1024) / node.memory.total) * 100)
        },
        allocated(node){
            var srvs = this.services.filter(srv => srv.node == node && srv.status != 'hibernating');

            var ram = function(pkg){
                if(!pkg) return 0;
                if(pkg == "pkg02") pkg = "pkg0.25";
                var clean = parseFloat(pkg.replace("pkg", ""));;
                if(clean == 0) clean = 0.5;
                return clean;
            }

            var reducer = function(e, v){
                return e + ram(v.package);
            }
            return srvs.reduce(reducer, 0)
        },
        async doRequests(){
            var Vue = this;
            const requests = [
                this.$http.get(this.StacketConfig.api.services + "/systemUsage", {headers: {"authorization": this.token}}),
                this.$http.get(this.StacketConfig.api.services + "/staff", {headers: {"authorization": this.token}})
            ];
            Promise.all(requests).then(async ([systemUsageResponse, staffResponse]) => {
                if(systemUsageResponse.data.error){
                    return Vue.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: systemUsageResponse.data.error
                    })
                }
                Vue.usage = systemUsageResponse.data;

                if(staffResponse.data.error){
                    return Vue.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: staffResponse.data.error
                    })
                }
                Vue.services = staffResponse.data;
            })
        },
        getStaffUsage(){
            this.$http.get(this.StacketConfig.api.services + "/systemUsage", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.usage = response.data;
            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch node usage!"
                })
            });
        },
        convertName(name) {
            var fullname = name+"";
            var split = fullname.split(" ");
            if(split.length == 1){
                return split[0].charAt(0) + split[0].charAt(1);
            } else {
                var firstName = split[0];
                var lastName = split[split.length-1];

                return firstName.charAt(0) + lastName.charAt(0);
            }

        },
        star(index, serviceId) {
            this.$http.post(this.StacketConfig.api.profile + "/star", {service: serviceId}, {headers: {"authorization": this.token}}).then((response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                if(response.data == "starred"){
                    this.$parent.user.starred.push(serviceId)
                } else if(response.data == "unstarred"){
                    for(var star in this.$parent.user.starred){
                        this.$parent.user.starred.splice(star + 1, 1);
                    }
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not star/unstar service!"
                })
            });
        },

        getStaffServices() {
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            this.$http.get(this.StacketConfig.api.services + "/staff", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                loader.hide();
                this.services = response.data;
                for(var service in this.services){
                    if(!this.services[service].team){
                        if(this.services[service].subusers && this.services[service].subusers.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.services[service]._id}/subusers`, {headers: {"authorization": this.token}});
                            this.services[service].subusers = resp.data;    
                        }
                    } else {
                        if(this.services[service].team.groups && this.services[service].team.groups.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.services[service]._id}/groups`, {headers: {"authorization": this.token}});
                            this.services[service].team.groups = resp.data;    
                        }
                    }
                }
                this.$parent.services = this.services;
            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch services!"
                })
            });
        }
    }
}  
</script>