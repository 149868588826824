<template>
    <b-container fluid>
        <b-modal id="newPort" v-bind:title="Lang.dashboard.service.allocations.newPort.title" ok-only centered @ok="newAllocation()" v-bind:okTitle="Lang.dashboard.service.allocations.newPort.save">
            <b-form-group
                v-bind:label="Lang.dashboard.service.allocations.newPort.selectIP"
            >
                <b-form-select v-model="Newallocation.ip" :options="IPOptions"></b-form-select>
            </b-form-group>
            <b-form-group v-if="Newallocation.ip != ''"
                v-bind:label="Lang.dashboard.service.allocations.newPort.enterPublic"
            >
                <b-form-input
                type="number" min="0" max="65535"
                v-model="Newallocation.public"
                required
                placeholder="25565"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                v-bind:label="Lang.dashboard.service.allocations.newPort.enterTarget"
            >
                <b-form-input
                type="number" min="0" max="65535"
                v-model="Newallocation.target"
                required
                placeholder="25565"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                v-bind:label="Lang.dashboard.service.allocations.newPort.udpEnabled"
            >
                <b-form-select
                :options="optionsUdp"
                v-model="Newallocation.udp"
                required
                placeholder="True"
                ></b-form-select>
            </b-form-group>
        </b-modal>
        <b-row style="margin-top: 10px;">
            <b-col>
                <h4>
                    {{ Lang.dashboard.service.allocations.allocationTitle }}
                </h4>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="3" style="text-align: center;">
                {{ Lang.dashboard.service.allocations.ip }}
            </b-col>
            <b-col cols="2" style="text-align: center;">
                {{ Lang.dashboard.service.allocations.public }}
            </b-col>
            <b-col cols="2" style="text-align: center;">
                {{ Lang.dashboard.service.allocations.target }}
            </b-col>
            <b-col cols="2" style="text-align: center;">
                {{ Lang.dashboard.service.allocations.udp }}
            </b-col>
            <b-col cols="2" style="text-align: center;">
                {{ Lang.dashboard.service.allocations.state }}
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-list-group>
                    <b-list-group-item v-for="(allocation, index) of service.service.allocations" v-bind:key="index">
                        <b-row>
                            <b-col cols="3" style="text-align: center; vertical-align: middle; min-width: 300px;">
                                <b-input-group>
                                    <b-form-input disabled type="text" :value="allocation.ip ? allocation.ip : network.ip"></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="2" style="text-align: center; min-width: 150px;">
                                <b-input-group>
                                    <b-form-input v-if="allocation.ip" @change="updateAllocation(allocation.id)" type="number" min="0" max="65535" v-model="service.service.allocations[index].public"></b-form-input>
                                    <b-form-input v-else disabled type="number" min="0" max="65535" v-model="service.service.allocations[index].public"></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="2" style="text-align: center; min-width: 150px;">
                                <b-input-group>
                                    <b-form-input @change="updateAllocation(allocation.id)" type="number" min="0" max="65535" v-model="service.service.allocations[index].target"></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col cols="2" style="text-align: center; min-width: 150px;">
                                <b-input-group>
                                    <b-form-select @change="updateAllocation(allocation.id)" :options="optionsUdp" v-model="service.service.allocations[index].udp"></b-form-select>
                                </b-input-group>
                            </b-col>
                            <b-col cols="2" style="text-align: center; min-width: 150px;">
                                <b-input-group>
                                    <b-form-select @change="updateAllocation(allocation.id)" :options="optionsDisabled" v-model="service.service.allocations[index].disabled"></b-form-select>
                                </b-input-group>
                            </b-col>
                            <b-col cols="1" v-if="allocation.default && allocation.default == true" style="text-align: center; min-width: 50px;" v-b-tooltip.hover v-bind:title="Lang.dashboard.service.allocations.deleteDefault">
                                <b-button variant="danger" disabled>{{ Lang.dashboard.service.allocations.delete }}</b-button>
                            </b-col>
                            <b-col cols="1" v-else style="text-align: center; min-width: 50px;">
                                <b-button variant="danger" @click="deleteAllocation(allocation.id)">{{ Lang.dashboard.service.allocations.delete }}</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
        <b-row class="justify-content-center" style="margin-top: 25px;">
            <b-col cols="4">
                <b-button variant="primary" style="width: 100%;" @click="newPort()">New Port</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

</script>
<style>

</style>
<script>

export default {
    name: "Allocations",
    components: {
        
    },
    props: [
        "service",
        "network"
    ],
    data: () => {
        return {
            token: "",
            //service: {},
            Newallocation: {
                public: 25565,
                target: 25565,
                ip: "",
                disabled: false,
                udp: false,
            },
            optionsDisabled: [
                {
                    value: true,
                    text: "Disabled"
                }, {
                    value: false,
                    text: "Enabled"
                } 
            ],
            optionsUdp: [
                {
                    value: false,
                    text: "Disabled"
                }, {
                    value: true,
                    text: "Enabled"
                }    
            ],
            networks: [],
            IPOptions: [],
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
       //this.service = this.$parent.service;
       this.optionsDisabled[0].text = this.Lang.dashboard.service.allocations.disabled
       this.optionsDisabled[1].text = this.Lang.dashboard.service.allocations.enabled
       this.optionsUdp[0].text = this.Lang.dashboard.service.allocations.disabled
       this.optionsUdp[1].text = this.Lang.dashboard.service.allocations.enabled

       this.getNetworks();
    },
    methods: {
        newPort(){
            this.Newallocation = {
                public: 25565,
                target: 25565,
                ip: "",
                disabled: false,
                udp: false,
                network: ""
            }
            this.IPOptions = [
                {
                    value: "",
                    text: this.network.ip
                }
            ];
            for(var network of this.service.service.networks){
                var nwk = this.networks.find(e => e._id == network.id);
                for(var ips of nwk.ipaddresses){
                    this.IPOptions.push({ 
                        value: {
                            ip: ips.ip,
                            network: nwk._id
                        },
                        text: ips.ip
                    })
                }
            }
            this.$bvModal.show('newPort');
        },
        getNetworks() {
            this.$http.get(this.StacketConfig.api.networks + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.networks = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch networks!"
                })
            });
        },
        newAllocation(){
            this.Newallocation.public = parseInt(this.Newallocation.public);
            this.Newallocation.target = parseInt(this.Newallocation.target);
            if(this.Newallocation.ip == ""){
                delete this.Newallocation.public;
                delete this.Newallocation.ip
            } else {
                this.Newallocation.network = this.Newallocation.ip.network;
                this.Newallocation.ip = this.Newallocation.ip.ip;
            }
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Adding Allocation..",
                text: "Please wait"
            })

            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, {allocation: this.Newallocation}, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.service.service.allocations.push(response.data);
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Allocation added!"
                })
            }).catch(err => {
                if(err.body.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: err.body.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not add allocation!"
                })
            });
        },
        updateAllocation(allocationId){
            var allo = this.service.service.allocations.find(all => all.id.toString() == allocationId.toString());
            console.log(allo)
            allo.public = parseInt(allo.public);
            allo.target = parseInt(allo.target);
            if(allo.ip == ""){
                allo.ip = null;
            }
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Updating Allocation..",
                text: "Please wait"
            })

            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, {allocation: allo}, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Allocation saved!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: err.data.error || "Could not save allocation!"
                })
                this.$parent.updateService();
            });
        },
        deleteAllocation(allocationId){
            var allo = this.service.service.allocations.find(all => all.id.toString() == allocationId.toString());
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Deleting Allocation..",
                text: "Please wait"
            })
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, {allocation: {id: allo.id, delete: true}}, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.service.service.allocations = this.service.service.allocations.filter(e => e.id != allocationId)
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Allocation deleted!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not delete allocation!"
                })
            });
        },
    }
}  
</script>