<template>
    <b-container fluid>
        <b-row align-h="end">
            <b-col>
                <h3 style="color: #4886FD;">SSH & VNC</h3>
            </b-col>
            <b-col class="ml-5">
                <i class="fas fa-circle" style="color:#2ead42!important;" v-if="service.status == 'online'"></i>
                <i class="fas fa-circle" style="color:#f13862!important;" v-else-if="service.status == 'offline'"></i>
                <i class="fas fa-circle" v-else-if="service.status == 'hibernating'"></i>
                <strong class="ml-3" style="color:#2ead42!important;" v-if="service.status == 'online'">{{ Lang.dashboard.service.online }}</strong>
                <strong class="ml-3" style="color:#f13862!important;" v-else-if="service.status == 'offline'">{{ Lang.dashboard.service.offline }}</strong>
                <strong class="ml-3" v-else-if="service.status == 'hibernating'">{{ Lang.dashboard.service.hibernate }}</strong>
            </b-col>
            <b-button class="mr-2" variant="success" v-on:click="$parent.startService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.start }}</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.stopService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.stop }}</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.killService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.kill }}</b-button>
            <b-button class="mr-2" variant="primary" v-on:click="$parent.hibernateService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.hibernate }}</b-button>
            <b-button class="mr-5" variant="warning" v-on:click="$parent.reactivateService() && setTimeout(this.$parent.getActions(), 1000)">{{ Lang.dashboard.service.controls.reactivate }}</b-button>
        </b-row>
        <hr>
        <b-row>
            <b-col>
                <log-viewer style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;" :log="log" :loading="isLoading" />
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                <h5 style="font-weight: bold;">
                    Connect with SSH
                </h5>
                Username: <b>root</b><br>
                Password: <b>uRRAXMH0RibkPyzBTkP3</b> <span style="font-style: italic;">(When you connect for the first time, you will be promted to change this password)</span><br>
                IP Address: <b>{{ service.service.node }}.stacket.net</b><br>
                Port: <b>{{ service.service.allocations.filter(e => e.default == true)[0].public }}</b><br>
                <br>
                <p>
                    <b>SSH Command</b><br>
                    ssh root@{{ service.service.node }}.stacket.net -p {{ service.service.allocations.filter(e => e.default == true)[0].public }}
                </p>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                <h5 style="font-weight: bold;">
                    Connect with VNC
                </h5>
                VNC is currently <b>{{ (service.service.allocations.filter(e => e.default == true)[1].disabled == true) ? "Disabled" : "Enabled" }}</b> <span style="font-style: italic;">(You can enable and disable this in the firewall by setting the firewall rule with target port 5900 to enabled, VNC should be disabled when not used since there is no authentication required to connect)</span><br>
                IP Address: <b>{{ service.service.node }}.stacket.net</b><br>
                Port: <b>{{ service.service.allocations.filter(e => e.default == true)[1].public }}</b><br>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

export default {
    name: "Console",
    props: [
        "service"
    ],
    data: () => {
        return {
            token: "",
            service: {},
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
    },
    methods: {}
}  
</script>